@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,700');
/* Color schemes */
.content {
	--color-text: #efedeb;
	--color-bg: #0e0e0e;
	--color-link: #1920f3;
	--color-link-hover: #555;
	--color-info: #fff;
	--color-main-text: #1d1c1c;
	--color-scroll-text: #3625d3;
	--font-scroll-text: 'Roboto Mono', monospace;
	--fontsize-scroll-text: 8vw;
}

.content {
	padding-top:90px;
}

.content__main {
	padding-top:100px;
	color: var(--color-main-text);
}

.content__text::before {
	content: '';
	position: absolute;
	left: 0;
	height: 69%;
	bottom: 0;
	width: calc(6vmax - 1.5rem);
	background: var(--color-link);
}

.content {
	position: relative;
	display: block;
}

.content__section {
	position: relative;
	text-align: right;
	padding: 0 6vmax;
}

.content__section:first-child {
	margin: 0 0 30vh;
	margin-top: 15vh;
	height: calc(100vh - 9.875em);
	text-align: left;
}

/* .content__section:last-child {
	margin: 30vh 0 0;
}

.content__section:first-child,
.content__section:last-child {
	height: calc(100vh - 9.875em);
	text-align: left;
} */

.content__link {
	display: block;
	position: relative;
	max-width: 100%;
	margin: 10vh 0 30vh auto;
}

.content__link--nobottom {
	margin-bottom: 10vh;
}

.content__img {
	position: relative;
	display: block;
	max-width: 90%;
	margin: 0 0 0 auto;
}

.content__3d {
	width: 700px;
	height: 500px;
	position: relative;
	margin: 0 0 0 auto;
}

.content__main,
.content__text {
	color: var(--color-scroll-text);
	font-size: var(--fontsize-scroll-text);
	font-family: var(--font-scroll-text);
	margin: 0;
	line-height: 1;
	text-transform: lowercase;
	font-weight: normal;
}

.content__main,
.content__contact {
	position: absolute;
	bottom: calc(var(--fontsize-scroll-text) * 1.5 + 6vmax);
}

.content__main span {
	display: block;
}

.content__text {
	position: absolute;
	bottom: 6vmax;
	left: 0;
	padding-left: 6vmax;
	z-index: 1000;
	line-height: 0.8;
}

.js .ioapi .content__text {
	position: fixed;
	opacity: 0;
	white-space: pre;
}

.content__text span {
	display: inline-block;
}

.content__contact {
	font-size: calc(var(--fontsize-scroll-text) / 2);
	font-family: var(--font-scroll-text);
}

.content__contact a:last-child {
	display: block;
}

.content__text span[data-initial=" "] {
	width: 40px;
}


@media screen and (max-width: 50em) {

	.content__main {
		padding-top: 240px;
	}

	
	.codrops-header {
		display: block;
		text-align: center;
		padding: 2.5rem 1rem 0;
	}

	.codrops-header__title {
		margin: 1rem 0;
	}

	.demos {
		margin: 0;
	}

	.info {
		padding: 0;
		position: relative;
		-webkit-writing-mode: initial;
		writing-mode: initial;
		text-align: center;
		bottom: auto;
		right: auto;
		margin: 0;
	}

	.github {
		display: none;
	}

	.content__text,
	.js.ioapi .content__text {
		bottom: 7rem;
	}

	.content__text span[data-initial=" "] {
		width: 28px;
	}


	.content__section:first-child
	/* .content__section:last-child */ {
		height: calc(100vh - 13.875em);
	}

	.content__main,
	.content__contact {
		bottom: calc(var(--fontsize-scroll-text) * 1.5 + 15vh);
	}
}



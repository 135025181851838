:root {
  --blue: #3625d3;
  --secondary: #666;
}

.jd_container {
  margin: 100px 60px;
  padding-top: 150px;
  max-width: 1000px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.5;
}

.jd_container section {
  margin-bottom: 3rem;
}

.jd_heading {
  margin-bottom: 3rem;
}
.job-heading-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.job-posted {
  text-align: left;
  font-size: 10px;
  padding: 12px 0px;
  color: gray;
}
.jd_heading > h1 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}
.jd_heading > p {
  color: var(--secondary);
}

.jd_container section h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.list > li {
  margin-bottom: 1rem;
}

.form_container {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
}
.form_container form {
  flex: 1;
}
.form_control {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

/* .form_control label{
    margin-bottom: 1rem;
} */
.form_control > input {
  width: 100%;
  padding: 10px 15px;
  /* border: 1px solid rgba(0,0,0,0.2); */
  outline: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: auto !important;
}

.form_control > button {
  padding: 10px 15px;
  border: none;
  background-color: var(--blue);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  height: auto !important;
}
.form_control .required::after {
  content: "*";
  color: red;
  margin-left: 3px;
}
.block_text {
  flex: 1;
  margin-left: 5em;
}
.form_container .block_text h1 {
  font-size: 95px;
  font-weight: 500;
}
.career {
  -webkit-text-stroke: 2px var(--blue);
  background-color: transparent;
  transition: 0.3s ease-in;
  color: transparent;
  /* background-color: blue; */
}
.career:hover {
  -webkit-text-fill-color: var(--blue);
}
.job_title {
  font-size: 32px;
  padding: 8px 0px;
  cursor: pointer;
}
.salery {
  padding: 16px 0px;
  font-size: 16px;
}
#buttonid {
  border: 1px solid blue;
  padding: 16px 48px;
  font-size: 16px;
  cursor: pointer;
  margin: 12px 0px;
  color: #000;
}
#buttonid:hover {
  background-color: blue;
  color: #fff;
}
.description_title {
  font-size: 24px;
}
ol {
  margin: 24px 0px;
}
ol li {
  list-style: circle;
  line-height: 1.8;
  font-size: 14px;
}
li {
  font-size: 14px;
}
p > strong {
  font-size: 20px;
  display: inline-block;
  margin: 18px 0px;
}
.apply-job {
  text-align: center;
}
.job-details {
  display: flex;
  align-items: center;
}
.job-designation,
.job-salery {
  font-size: 14px;
}
.awssld__bullets button {
  width: 4px !important;
  height: 4px !important;
  border-radius: 50% !important;
}
.awssld__bullets .awssld__bullets--active {
  transform: scale(1.5) !important;
}
@media only screen and (max-width: 950px) {
  .jd_container {
    max-width: 90vw;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    box-sizing: border-box;
  }
  .jd_container section p {
    text-align: justify;
  }
  .list {
    text-align: justify;
  }
}

@media only screen and (max-width: 600px) {
  .jd_container {
    max-width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }
  .jd_container section h2 {
    font-size: 1.8rem;
  }
  .job-heading-details {
    flex-flow: column;
  }
  .job-details {
    flex-flow: column;
    align-items: normal;
  }
  #buttonid {
    background-color: blue;
    color: #fff;
  }
}

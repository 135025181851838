.section.section__products {
    width: 100%;
    padding-top: 240px;
    padding-bottom: 0;
    z-index: 5;
    position: relative;
}

.section__products {
    width: 100%;
    padding-top: 220px;
    padding-bottom: 0;
}

.wrapper {
    position: relative;
    display: block;
    margin: 0 auto;
    right: 0;
    max-width: 1300px;
    width: 90%;
    z-index: 1;
}

.wrap-padding {
    padding: 0 100px;
}

@media screen and (max-width: 1200px) {
    .wrap-padding {
        padding: 0 50px;
    }
}

@media screen and (max-width: 1100px) {
    .wrap-padding {
        padding: 0;
    }
}

.product {
    width: calc(50% - 44px);
    display: inline-block;
    margin: 0;
    margin-right: 80px;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
    .product {
        width: 100%;
        margin: 0;
    }
}

.products_title {
    font-size: 40px;
    padding-bottom: 40px;
}

@media screen and (max-width: 600px) {
    .products_title {
        font-size: 35px;
        margin-left: 0;
        padding-bottom: 30px;
    }
}



.product.img:hover {
    width: 105%;
}

.product:nth-child(2n + 2) {
    margin-right: 0;
    padding-top: 80px;
}

@media screen and (max-width: 600px) {
    .product:not(:first-of-type) {
        margin-top: 30px;
        padding-top: 0;
        width: 100%;
    }
}

.product .img {
    position: relative;
    width: 100%;
    padding-bottom: 58%;
    overflow: hidden;
}

.product .img:hover {
    width: 105% !important;
}

.product .text {
    text-align: left;
    position: relative;
    padding: 10%;
    background: transparent;
    transition: all .5s;
}

@media screen and (max-width: 800px) {
    .product .text {
        padding-left: 0 !important;
    }
}

@media screen and (max-width: 600px) {
    .product .text {
        padding: 5% !important;
    }
}

.product .text h5 {
    margin: 0;
    padding-bottom: 5px;
    font-size: 30px;
    letter-spacing: -1px;
    font-weight: 400;
}

.product .text p {
    font-weight: 400;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: -.015em;
    margin: 0;
}

.product .img .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: all .7s;
}

.c-grey {
    color: #343434;
    transition: all .3s
}

.c-lightgrey {
    color: #9e9e9e;
    transition: all .3s
}

.enter-y {
    will-change: transform, opacity;
    transform: translateY(50px) rotate(2deg);
    opacity: 0;
    transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -webkit-transform: translateY(50px) rotate(2deg);
    -moz-transform: translateY(50px) rotate(2deg);
    -ms-transform: translateY(50px) rotate(2deg);
    -o-transform: translateY(50px);
    -webkit-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -moz-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -ms-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -o-transition: all 1.2s cubic-bezier(.37, .01, 0, .98)
}

.enter-y-r {
    will-change: transform, opacity;
    transform: translateY(-50px) rotate(2deg);
    opacity: 0;
    transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -webkit-transform: translateY(-50px) rotate(2deg);
    -moz-transform: translateY(-50px) rotate(2deg);
    -ms-transform: translateY(-50px) rotate(2deg);
    -o-transform: translateY(-50px);
    -webkit-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -moz-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -ms-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -o-transition: all 1.2s cubic-bezier(.37, .01, 0, .98)
}

.enter-x {
    will-change: transform, opacity;
    transform: translateX(50px) rotate(2deg);
    opacity: 0;
    transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -webkit-transform: translateX(50px) rotate(2deg);
    -moz-transform: translateX(50px) rotate(2deg);
    -ms-transform: translateX(50px) rotate(2deg);
    -o-transform: translateX(50px);
    -webkit-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -moz-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -ms-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -o-transition: all 1.2s cubic-bezier(.37, .01, 0, .98)
}

.enter-x-r {
    will-change: transform, opacity;
    transform: translateX(-50px) rotate(2deg);
    opacity: 0;
    transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -webkit-transform: translateX(-50px) rotate(2deg);
    -moz-transform: translateX(-50px) rotate(2deg);
    -ms-transform: translateX(-50px) rotate(2deg);
    -o-transform: translateX(-50px) rotate(2deg);
    -webkit-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -moz-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -ms-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -o-transition: all 1.2s cubic-bezier(.37, .01, 0, .98)
}

.show .delay-1 {
    transition-delay: .05s !important
}

.show .delay-2 {
    transition-delay: .1s !important
}

.show .delay-3 {
    transition-delay: .15s !important
}

.show .delay-4 {
    transition-delay: .2s !important
}

.show .delay-5 {
    transition-delay: .25s !important
}

.show .delay-6 {
    transition-delay: .3s !important
}

.show .delay-7 {
    transition-delay: .35s !important
}

.show .delay-8 {
    transition-delay: .4s !important
}

.show .delay-9 {
    transition-delay: .45s !important
}

.show .delay-10 {
    transition-delay: .5s !important
}

.show .enter-y,
.show .enter-x,
.show .enter-y-r,
.show .enter-x-r {
    opacity: 1;
    transform: translate(0) scale(1) rotate(0)
}



.dw-container {
    max-width: calc(100% - 80px);
    margin: 0 auto;
    position: relative;
}

.dw-wrapper {
    width: 1180px;
}

.dw-part {
    text-align: center;
}

.dw-product {
    text-align: left;
    font-family: inherit;
    font-weight: inherit;
    font-variation-settings: inherit;
    font-style: inherit;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit;
    color: inherit;
}


@font-face {
    src: url('../assets/fonts/Bahnschrift.woff2') format('woff2-variations');
    font-family: 'Bahnschrift';
    font-style: normal;
}

.dw-name {
    padding-top: 130px;
}

.dw-variable-font {
    font-variation-settings: 'wght'800, 'opsz'20;
    font-size: 5em;
    margin-top: 0;
    color: var(--pink);
    font-family: Bahnschrift;
}

@media (max-width: 575.98px) {
    .dw-name {
        /*  font-size: 50px;
        font-weight: 400; */
    }

    .dw-variable-font {
        font-size: 3em;
    }
}

.dw-f-one {
    width: 100%;
    user-select: none;
}

.dw-f-one-part {
    margin: 20px 0;
}

.dw-about {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 50px 0px;
}

.dw-about .dw-para,
.dw-about .dw-desc {
    flex-basis: 50%;
}

@media (max-width: 768px) {
    .dw-about {
        padding: 0;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 72px;
    }

    .dw-desc {
        margin-left: 0;
        margin-bottom: 30px;
        order: -1;
    }
}



@media (max-width: 1110px) {
    .dw-about {
        padding: 0;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 72px;
    }

    .dw-about .dw-desc {
        margin-bottom: 25px;
        order: -1;
        width: 100%;
        justify-content: center;
    }

    .dw-about .dw-para {
        max-width: 100%;
    }

    .dw-para .dw-content-text {
        text-align: center;
    }

    .dw-content-text {
        font-size: 1.05rem;
    }
}

.dw-product-share-btn {
    margin:  0 48px;
}

.dw-para {
    max-width: 580px;
}

.dw-content-text {
    line-height: 1.9;
    user-select: text;
    font-size: 1.25rem;
    color: #595959;
}


.dw-desc {
    display: flex;
    flex: none;
    height: 100%;
    justify-content: flex-end;
}

.dw-cap {
    font-size: 23px;
    font-weight: 500;
}

.dw-list {
    list-style-type: none;
    font-size: 20px;
    font-weight: 400;
    margin-left: 20px;
    border-left: 4px solid #3625D3;
}

.dw-list li {
    margin: 2px 0 10px 10px;
}


.dw-video {
    padding-top: 60px;
    padding-bottom: 60px;
}

.dw-video.dw-youtube {}

.dw-video video {
    width: 100%;
}

.dw-content {
    padding-top: 50px;
}

.dw-content-part {
    padding: 20px 0;
}

.dw-para-main {
    max-width: 400px;
}

.dw-flex-para {
    display: flex;
    padding: 0 100px;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
}

.dw-para-container {
    padding: 0 50px;
}

.dw-car {
    max-width: 400px;
    display: flex;
    align-items: center;
}



/* Extra small devices (portrait phones, less than 576px)*/

@media (max-width: 575.98px) {
    .dw-text .dw-para-container {
        padding: 0
    }

    .dw-text .dw-flex-para {
        padding: 0;
    }


}

.dw-para-list {
    display: flex;
    justify-content: center;
}

.dw-para-list ul {
    padding: 10px 0;
}

.dw-para-list ul li {
    padding: 6px 0;
    font-size: 14px;
    list-style: square;
    line-height: 2;
    font-weight: lighter;
    color: black;
}

@media (max-width: 575.98px) {
    .dw-para-list ul {
        padding-left: 20px;
    }
}


/*buttons*/

.dw-other-product {
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
}

.dw-others {
    position: relative;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.dw-others.dw-previous span {
    left: 150%;
}

.dw-others.dw-previous .dw-arrow {
    transform: translate(20px, 0);
    transform-origin: 0% 50%;
}

.dw-others.dw-previous .dw-arrow .dw-arrow-line {
    transform: scaleX(0.4);
    transform-origin: 0% 50%;
}

.dw-others.dw-next {
    right: 10px;
}

.dw-others.dw-next .dw-next-previous-name {
    right: 150%;
}

.dw-others.dw-next .dw-arrow {
    transform: scaleX(-1) translate(60px, 0);
    transform-origin: 100% 50%;
}

.dw-others.dw-next .dw-arrow .dw-arrow-line {
    transform: scaleX(0.4);
    transform-origin: 0% 50%;
}

.dw-others:hover.dw-previous .dw-arrow .dw-arrow-line {
    transform: scaleX(1);
}

.dw-others:hover.dw-previous .dw-circle-blue circle {
    stroke-dashoffset: 0;
}

.dw-others:hover.dw-next .dw-arrow .dw-arrow-line {
    transform: scaleX(1);
}

.dw-others:hover.dw-next .dw-circle-blue circle {
    stroke-dashoffset: 0;
}

.dw-others:hover .dw-next-previous-name {
    opacity: 1;
}

.dw-others:focus {
    outline: none;
}

.dw-others .dw-next-previous-name {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 0 10px;
    opacity: 0;
    transition: all 0.3s ease-out;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: #3625D3;
    font-size: 0.76471rem;
}

.dw-others .dw-arrow {
    width: 50px;
    transition: all 0.3s ease-out;
}

.dw-others .dw-arrow * {
    transition: all 0.3s ease-out;
}

.dw-others .dw-circle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    z-index: -2;
    transition: all 0.5s ease-out;
}

.dw-others .dw-circle circle {
    stroke-dasharray: 46.25px;
    stroke-dashoffset: 0px;
}

.dw-others .dw-circle-blue {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    z-index: -1;
    transition: all 0.5s ease-out;
}

.dw-others .dw-circle-blue circle {
    transition: all 0.4s ease-out;
    stroke-dasharray: 92.5px;
    stroke-dashoffset: 92.5px;
}

.dw-svg {
    padding: 30px;
}

@media (max-width: 575.98px) {
    .dw-svg .dw-others .dw-circle-blue {
        width: 50px;
        height: 50px;
    }

    .dw-svg .dw-others .dw-circle {
        width: 50px;
        height: 50px;
    }

    .dw-svg .dw-others.dw-next .dw-arrow .dw-arrow-line {
        transform: scaleX(0.3);
    }

    .dw-svg .dw-others.dw-previous .dw-arrow .dw-arrow-line {
        transform: scaleX(0.3);
    }

    .dw-svg .dw-others.dw-next {
        right: 0;
    }

    .dw-svg .dw-others.dw-next .dw-arrow {
        transform: scaleX(-1) translate(70px, 0);
    }

    .dw-svg .dw-others:hover.dw-next .dw-arrow .dw-arrow-line {
        transform: scaleX(0.8);
    }

    .dw-svg .dw-others:hover.dw-previous .dw-arrow .dw-arrow-line {
        transform: scaleX(0.8);
    }

    .dw-others.dw-previous span {
        left: 120%;
    }

    .dw-others.dw-next .dw-next-previous-name {
        right: 120%;
    }

    .dw-others .dw-next-previous-name {
        letter-spacing: 0.9px;
        font-size: 0.7rem;
    }

    .dw-svg {
        padding: 10px;
    }
}





/* Image Grid */

.dw-container-yola {
    padding: 130px 50px;
    background-color: #F6F7FF;
}

.dw-grid-wrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    color: #444;
}

@media (max-width: 575.98px) {
    .dw-main .dw-container-yola .dw-grid-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
    }

    .dw-main .dw-container-yola {
        padding: 100px 0;
    }
}

@media (max-width: 991.98px) {
    .dw-desc {
        margin-left: 0;
        margin-bottom: 30px;
        order: -1;
    }
}

.dw-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
}

.ytp-pause-overlay,
.ytp-title,
.ytp-show-cards-title,
.ytp-show-tiles {
    display: none !important;
}
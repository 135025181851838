.footer {
    height: auto;
    padding: 100px 0;
    margin-top: 50px;
    -ms-flex-align: center;
    align-items: center;
    transition: all .3s ease;
    overflow: hidden;
    z-index: 2;
    color: #fff !important;
}

.footer,
.footer_container {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
}

.footer_container {
    -ms-flex-align: start;
    align-items: flex-start
}

@media (max-width: 750px) {
    .footer_container {
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: left;
    }
}

.container_content {
    max-width: 1280px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.footer_contact {
    width: 50%;
    position: relative;
    top: -18px;
}


@media (max-width: 750px) {
    .footer_contact {
        width: 100%;
        margin-bottom: 0;
        top: auto;
    }
}

.footer_contact_link {
    color: inherit;
    outline: none;
    text-decoration: none;
    color: #fff;
    transition: all .5s ease;
}

.footer_contact_link {
    fill: var(--main-bg-color);
    stroke: #fff;
}

.footer_contact_link_text {
    font-family: Euclid Circular B;
    font-size: 95px;
    font-weight: 500;
    letter-spacing: -5.1px;
    max-width: 400px;
    transition: all 2s ease !important;
}

.footer_contact_link_text:hover {
    -webkit-text-stroke: 1.0px #fff;
    -webkit-text-fill-color: var(--main-bg-color);
}

@media (max-width: 750px) {
    .footer_contact_link_text {
        width: 100%;
        margin-bottom: 40px;
        top: auto
    }
}

@media (max-width: 1500px) {
    .footer_contact_link_text {
        font-size: 64px;
        max-width: 300px
    }
}

@media (max-width: 750px) {
    .footer_contact_link_text {
        max-width: 250px;
        font-size: 45px;
        letter-spacing: -2.41px
    }
}

.footer_navigation {
    width: calc(25% - 12px);
    margin-left: 12px;
}

.footer_info {
    width: 25%;
}

@media (max-width: 750px) {
    .footer_navigation {
        width: 40%;
        margin-left: 5px;
    }

    .footer_info {
        width: 50%;
    }
}



.footer_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--main-bg-color);
    width: 100%;
    height: 100vh;
    z-index: -1;
    transform: translate3d(0, 100%, 0);
    transition: transform .9s cubic-bezier(.785, .135, .15, .86)
}

@media (max-width: 750px) {
    /* .footer_wrapper {
        ;
        top: auto;
        bottom: 0
    } */
}

.footer.active .footer_wrapper {
    transform: translateZ(0);
    transition: transform .9s cubic-bezier(.785, .135, .15, .86)
}

.footer.active .footer_clip {
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(0 0 0 0);
    transition: all .9s cubic-bezier(.785, .135, .15, .86)
}

.footer_navigation li {
    padding-bottom: 20px;
    font-size: 22px;
    opacity: .6;
    transition: all .3s ease;
    cursor: pointer;
}

.footer_navigation li:hover {
    opacity: 1;
}

.footer_navigation_title,
.footer_info_title {
    font-size: 30px;
    padding-bottom: 25px;
    cursor: default;
}

.footer_info_content {
    line-height: 30px;
    font-size: 20px;
    opacity: .6;
    transition: all .3s ease;
    cursor: text;
}

@media (max-width: 750px) {
    .footer_info_content {
        font-size: 16px;
        line-height: 25px;
    }
}

.footer_socials {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0
}

.footer_socials_right {
    display: block;
    position: absolute;
    bottom: 20px;
    right: 0
}


.footer_socials_right span {
    padding: 0px 50px;
    opacity: .6;
}


.footer_socials li {
    list-style-type: none;
    display: inline;
    margin: 5px;
    padding: 0px 50px;
}

.footer_socials_list a {
    opacity: .6;
    transition: all .3s ease;
    cursor: pointer;
}

.footer_socials_list a:hover {
    opacity: 1;
}



@media (max-width: 750px) {
    .footer_socials li {
        padding: 0px 7px;
        margin: 0;
        font-size: 13px;
    }

    .footer_socials_right span {
        padding: 0px 7px;
        margin: 0;
        font-size: 9px;
    }


    .footer_socials_list a::after {
        content: "";
        padding: 0px 5px;
        border-right: 2px solid white;
    }
}

.social_icon {
    width: 20px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
}
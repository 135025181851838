.contact_section {
    width: 100%;
    padding-top: 240px;
    padding-bottom: 0;
}

@media (max-width: 1600px) {
    .contact_section {
        padding-top: 180px;
    }    
}

@media (max-width: 1500px) {
    .contact_section {
        padding-top: 150px;
        padding-bottom: 100px;
    }
}

@media (max-width: 750px) {
    .contact_section {
        padding-top: 120px;
        padding-bottom: 75px;
    }  
}

.contact_container {
    width:100%;
    position: relative;
    z-index: 3;
}

.contact_main_container {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media (max-width: 750px) {
    .contact_main_container {
        -ms-flex-direction: column;
        flex-direction: column;
    }
}


.contact_left {
    width: calc(50% - 12px);
    z-index: 2;
}

@media (max-width: 750px) {
    .contact_left {
        width: 100%;
    }
}

.contact_left p {
    font-family: Euclid Circular B;
    font-size: 100px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -5.1px;
    color: #000;
    max-width: 520px;
}

@media (max-width: 1500px) {
    .contact_left p {
        font-size: 45px;
        letter-spacing: -2.41px;
    }
}

.contact_right {
    width: calc(50% - 12px);
    margin-top: 150px;
    z-index: 2;
}

.contact_right p {
    font-family: Euclid Circular B;
    font-size: 40px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color: #7a7a7a;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease,opacity 1.6s ease;
    transition-delay: .6s;
    will-change: transform,opacity;
}

@media (max-width: 1500px) {
    .contact_right p {
        font-size: 30px;
        letter-spacing: -.48px;  
    }
}

@media (max-width: 1050px) {
    .contact_right {
        margin-top: 80px;
    }
}

@media (max-width: 1050px) {
    .contact_right {
        width: 55%;
        margin-top: 70px;
    }
}

@media (max-width: 750px) {
    .contact_right {
        width: 100%;
        margin-top: 57px;
    }
}

.line-1{
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
  animation: typewriter 4s steps(44) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
  from{width: 0;}
  to{width: 24em;}
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(255,255,255,.75);}
  to{border-right-color: transparent;}
}

.map_section {
    border:0;
    position: relative;
    z-index: 3;
    padding-top: 60px;
    padding-bottom: 0;
    width: 100%;
    height: 500px;
}

body.-contact .footer {
    margin-top: 0 !important;
}

.contact_left .select {
    background-color: var(--main-bg-color1);
    color: white;
    padding: 10px 4px;
}

@media (max-width: 750px) {
    .contact_left .select {
        padding: 2px 2px;
    }
}

.line {
    position: relative;
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.line:after {
    position: absolute;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0;
    background: #000;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.line:hover:after {
    width: 100%
}
.from_section{ 
    width: 100%;
    padding-top: 180px;
    padding-bottom: 0;
}

@media (max-width: 1600px) {
    .from_section {
        padding-top: 150px;
    }    
}

@media (max-width: 1500px) {
    .from_section {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media (max-width: 750px) {
    .from_section {
        padding-top: 50px;
        padding-bottom: 75px;
    }  
}

.form_content {
    max-width: 1500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.form_container {
    width:100%;
    position: relative;
    z-index: 10;
}

.from_section .container_content {
    font-size: 30px;
}

.form_title {
    font-size: 60px;
    padding-bottom: 80px;
}

@media (max-width: 1600px) {
    .form_title {
        font-size: 50px;
        padding-left: 10px;
        padding-bottom: 70px;
    }  
}

@media (max-width: 1500px) {
    .form_title {
        font-size: 40px;
        padding-bottom: 60px;
    }
}

@media (max-width: 750px) {
    .form_title {
        font-size: 35px;
      
        padding-bottom: 40px;
    }
}

.contact_form {
    font-size: 30px;
}

.input-field {
    display: block;
    padding:20px 0px; 
    width:50%;
    height: 20px;
    background-color: none;
    letter-spacing: .01875em;
    font-weight: 300;
    border-bottom: 2px solid var(--main-bg-color);
    transition:         0.08s ease-in;
    -o-transition:      0.08s ease-in;
    -ms-transition:     0.08s ease-in;
    -moz-transition:    0.08s ease-in;
    -webkit-transition: 0.08s ease-in;
}

@media (max-width: 1600px) {
    .input-field {
        width:50%;
    }  
}

@media (max-width: 1500px) {
    .input-field {
        width:60%;
    }
}

@media (max-width: 750px) {
    .input-field {
        width:80%;
    }
}

.input-field:after {
    padding-bottom: 130px 0px;
}

.input-container {
    padding-bottom: 20px;
    height: auto;
    line-height: 2em;;
}

@media (max-width: 1600px) {
    .input-container {
        padding-bottom: 20px;
        padding-left: 15px;
    } 
}



.input-field.textarea {
    resize: none;
    padding: 0;
    height: 100px !important;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .3s;
}

.input-field.textarea:hover {
    height: 200px !important;
}

.error {
    color:red;
    font-size: 20px;
    height: 30px;
}

.btn {
    position: relative;
    line-height: 0;
    border:2px solid var(--main-bg-color);
    padding: 32px;
    color: var(--main-bg-color);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    height: auto;
    transition:         0.08s ease-in;
    -o-transition:      0.08s ease-in;
    -ms-transition:     0.08s ease-in;
    -moz-transition:    0.08s ease-in;
    -webkit-transition: 0.08s ease-in;
    overflow:hidden;
    z-index: 1;
}

@media (max-width: 1600px) {
    .btn {
        margin-left: 15px;
    }  
}

@media (max-width: 1500px) {
    .btn {
        margin-left: 15px;
    } 
}

@media (max-width: 750px) {
    .btn {
        margin-left: 15px;
    } 
}

  
.fill:hover {
    color: whitesmoke;
}

.fill:before {
    content: "";
    position: absolute;
    background: var(--main-bg-color);
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.09s ease-in;
}

.fill:hover:before {
    top: 0;
}

.message_response {
    padding-left: 30px;
}
.enter-y {
    opacity: 1;
    transform: translate(0) scale(1) rotate(0);
}

.delay {
    transition-delay: .75s!important;
}

.bg-text {
    font-size: 40vw;
    font-family: 'Euclid Circular B';
    color: #f5f5f5;
    z-index: 4;
    position: relative;
    transition: color .5s;
    letter-spacing: -10px;
    pointer-events: none
}
.shadow__text {
    position: absolute;
    margin-left: 5px;
}

.bg-text span {
    display: block;
    position: relative;
    transition: color .5s;
    font-weight: 100;
}
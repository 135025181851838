.friends_title {
    width: 1340px;
    margin: 0 auto;
    box-sizing: border-box;
    height: auto;
    padding-top : 125px;
    position: relative;
    z-index: 3;
    -webkit-transition: -webkit-transform 1s cubic-bezier(.19,1,.22,1);
    transition: -webkit-transform 1s cubic-bezier(.19,1,.22,1);
    transition: transform 1s cubic-bezier(.19,1,.22,1);
    transition: transform 1s cubic-bezier(.19,1,.22,1),-webkit-transform 1s cubic-bezier(.19,1,.22,1)
}

.friends_title_text {
    font-family: Euclid Circular B;
    font-size: 30px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color: #505050;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease,opacity 1.6s ease;
    transition-delay: .6s;
    will-change: transform,opacity;
    
}

@media (max-width: 1740px) {
    .friends_title {
        padding-top:30px;
        padding-right:10px;
    }
    .friends_title_text {
       font-size: 25px;
       padding-left: 30px;
    }
}


@media (max-width: 1223px) {
    .friends_title {
        display: inline;
        padding-top:20px;
        padding-right:10px;
    }
    .friends_title_text {
       font-size: 25px;
       padding-left: 20px;
    }
}

@media (max-width: 750px) {
    .friends_title {
        display: inline;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        height: auto;
        padding-left: 20px;
        padding-top : 10px;
        position: relative;
        z-index: 3;
    }
    .friends_title_text {
       font-size: 25px;
       padding-left: 10px;
    }
}

.section-friends .friends .section-progress {
    top: 240px;

}

@media (max-width: 1740px) {
    .section-friends .friends .section-progress {
        top:140px
    }
}

.section-friends {
    padding-bottom: 0
}

@media (max-width: 767px) {
    .section-friends {
        padding-top:0
    }
}

.section-friends .section-index {
    top: 50px;
    right: calc(50vw - 720px)
}

@media (max-width: 1740px) {
    .section-friends .section-index {
        right:calc(50vw - 512px)
    }
}

.section-friends .wrapper {
    padding-bottom: 200px;
    position: relative
}

@media (max-width: 1740px) {
    .section-friends .wrapper {
        padding-bottom:100px
    }
}

.section-friends .friends-visual-01 {
    position: absolute;
    pointer-events: none;
    top: 100px;
    right: 50%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: 140px
}

@media (max-width: 1740px) {
    .section-friends .friends-visual-01 {
        width:100px!important;
        top: 82px
    }
}

@media (max-width: 767px) {
    .section-friends .friends-visual-01 {
        left:0
    }
}

.section-friends .friends {
    position: relative;
    padding-bottom: 50px
}

@media (max-width: 1223px) {
    .section-friends .friends {
        padding-bottom:10px
    }
}

@media (max-width: 767px) {
    .section-friends .friends {
        padding-bottom:0
    }
}

.section-friends .friends:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 240px;
    display: block;
    content: "";
}

@media (max-width: 1740px) {
    .section-friends .friends:before {
        height:140px
    }
}

@media (max-width: 767px) {
    .section-friends .friends:before {
        display:none
    }
}

.section-friends .friends__content {
    position: relative;
    z-index: 2;
    width: 1440px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 240px 40px 240px;
  /*   background: #1e1541 */
}


@media (max-width: 1740px) {
    .section-friends .friends__content {
        width:1024px;
        padding: 40px
    }
}

@media (max-width: 1223px) {
    .section-friends .friends__content {
        width:100%;
        padding: 35px;
    }
}


@media (max-width: 767px) {
    .section-friends .friends__content {
        padding:20px 30px
    }
}

.friends__image {
    width: 100%;
    height: auto;
}

.friends__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.friends__list a {
    width: 25%;
}

.friends__list a.katomaran {
    width: 30%;
}



@media (max-width: 1740px) {
    .friends__list a {
        width: 22%;
    }
    
}

@media (max-width: 1223px) {
    .friends__list a {
        width: 25%;
    }
    
}


@media (max-width: 767px) {
    .friends__list a {
        width: 30%;
    }    
}
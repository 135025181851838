/* :root {
  --main-bg-color: #0700fffc;  
  --main-bg-color1: #0a03ff ;
  --main-selection: rgba(7,0,255,0.99);
  --main-selection-text : #fff;
  --logo-fill : #27aae1;
  --anchor-bg-color: #f5f5f5;
  --client-border-color : #0a03ff;
} */

:root {
  --main-bg-color: #3625d3;
  --main-bg-color1: #3625d3;
  --main-selection: #3625d3;
  --main-selection-text: #fff;
  --logo-fill: #3625d3;
  --anchor-bg-color: #f5f5f5;
  --sub-title: #7a7a7a;
  --client-border-color: #0a03ff;
  --button-color: #3625d3
}


body {
  font-family: Helvetica, Arial;
}


@font-face {
  font-family: Euclid Circular B;
  font-display: swap;
  src: url(../assets/fonts/EuclidCircularB-Bold.woff2) format("woff2"), url(../assets/fonts/EuclidCircularB-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: Euclid Circular B;
  font-display: swap;
  src: url(../assets/fonts/EuclidCircularB-Semibold.woff2) format("woff2"), url(../assets/fonts/EuclidCircularB-Semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: Euclid Circular B;
  font-display: swap;
  src: url(../assets/fonts/EuclidCircularB-Light.woff2) format("woff2"), url(../assets/fonts/EuclidCircularB-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: Euclid Circular B;
  font-display: swap;
  src: url(../assets/fonts/EuclidCircularB-Medium.woff2) format("woff2"), url(../assets/fonts/EuclidCircularB-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: Euclid Circular B;
  font-display: auto;
  src: url(../assets/fonts/EuclidCircularB-Regular.woff2) format("woff2"), url(../assets/fonts/EuclidCircularB-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal
}


a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block
}

body {
  line-height: 1
}

ol,
ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

*,
:after,
:before {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

button,
input,
select,
textarea {
  color: inherit;
  font-size: inherit;
  font-style: inherit;
  font-family: inherit;
  border-radius: 0;
  -webkit-padding-start: 0;
  -ms-flex-align: start;
  align-items: flex-start;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  line-height: 1em
}

button,
input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=file],
input[type=month],
input[type=number],
input[type=password],
input[type=reset],
input[type=search],
input[type=submit],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select,
textarea {
  height: 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

input[type=color] {
  width: 1em
}

input::-ms-clear {
  display: none
}

details,
summary {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%
}

mark {
  background: none
}

hr {
  height: 1px;
  margin: 0;
  padding: 0
}

u {
  text-decoration: none
}

table th {
  text-align: left
}

a {
  color: inherit;
  outline: none;
  text-decoration: none
}

* {
  box-sizing: border-box
}

body {
  font-family: Euclid Circular B;
  text-size-adjust: 100%;
}

body {
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Euclid Circular B;
  background-color: #f5f5f5
}


.container {
  width: 100%;
}

.dw-loader_fill {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  pointer-events: none;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -ms-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: height 0s .5s, -webkit-transform .5s ease;
  transition: height 0s .5s, -webkit-transform .5s ease;
  -o-transition: height 0s .5s, -o-transform .5s ease;
  -moz-transition: transform .5s ease, height 0s .5s, -moz-transform .5s ease;
  transition: transform .5s ease, height 0s .5s;
  transition: transform .5s ease, height 0s .5s, -webkit-transform .5s ease, -moz-transform .5s ease, -o-transform .5s ease
}

.dw-loader_fill.-visible {
  pointer-events: auto;
  height: 100%;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: height 0s, -webkit-transform 0s;
  transition: height 0s, -webkit-transform 0s;
  -o-transition: height 0s, -o-transform 0s;
  -moz-transition: transform 0s, height 0s, -moz-transform 0s;
  transition: transform 0s, height 0s;
  transition: transform 0s, height 0s, -webkit-transform 0s, -moz-transform 0s, -o-transform 0s;
  -webkit-animation: cb-loader_fill-show .5s ease;
  -moz-animation: cb-loader_fill-show .5s ease;
  -o-animation: cb-loader_fill-show .5s ease;
  animation: cb-loader_fill-show .5s ease
}


@-webkit-keyframes cb-loader_fill-show {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center
  }
}

@-moz-keyframes cb-loader_fill-show {
  0% {
    -moz-transform: scaleY(0);
    transform: scaleY(0);
    -moz-transform-origin: bottom center;
    transform-origin: bottom center
  }

  100% {
    -moz-transform: scaleY(1);
    transform: scaleY(1);
    -moz-transform-origin: bottom center;
    transform-origin: bottom center
  }
}

@-o-keyframes cb-loader_fill-show {
  0% {
    -o-transform: scaleY(0);
    transform: scaleY(0);
    -o-transform-origin: bottom center;
    transform-origin: bottom center
  }

  100% {
    -o-transform: scaleY(1);
    transform: scaleY(1);
    -o-transform-origin: bottom center;
    transform-origin: bottom center
  }
}

@keyframes cb-loader_fill-show {
  0% {
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom center;
    -moz-transform-origin: bottom center;
    -o-transform-origin: bottom center;
    transform-origin: bottom center
  }

  100% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: bottom center;
    -moz-transform-origin: bottom center;
    -o-transform-origin: bottom center;
    transform-origin: bottom center
  }
}
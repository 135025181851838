::selection {
    background: var(--main-selection);
    color: var(--main-selection-text)
}

::-moz-selection {
    background: var(--main-selection);
    color: var(--main-selection-text)
}

.header {
	position: fixed;
	left: 80px;
	right: 80px;
	top: 80px;
	z-index: 1000;
	transition: all .5s ease
}

@media (max-width:1600px) {
	.header {
		left: 50px;
		right: 50px;
		top: 50px
	}
}

.header.hideAnimation {
	pointer-events: none;
	opacity: 0;
	transition: all .5s ease
}

@media (max-width:1600px) {
	.header {
		left: 50px;
		right: 50px
	}
}

@media (max-width:750px) {
	.header {
		/* opacity: 1!important; commented since we also need to hide it in mobile view*/
		width: 100%;
		top: 0;
		left: 0
	}
}

body.openMobileMenu .header_menu {
	clip-path: inset(0 0 0 0);
	-webkit-clip-path: inset(0 0 0 0);
	visibility: visible;
	transition: visibility 0s 0s, all .5s cubic-bezier(.785, .135, .15, .86) .1s
}

body.openMobileMenu .header_socials {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s 0s, all .5s ease .1s
}

body.openMobileMenu .header_logo path,
body.openMobileMenu .header_logo polygon,
body.openMobileMenu .header_logo polyline {
	fill: #fff;
	transition: all .9s ease
}

body.openMobileMenu .header_contactBtn {
	opacity: 0;
	transition: all .5s ease
}

@media (max-width:750px) {
	body.mobileMenuTrans .header_menu {
		clip-path: inset(0 0 100% 0);
		-webkit-clip-path: inset(0 0 100% 0);
		visibility: visible;
		transition: visibility 0s 0s, all .7s cubic-bezier(.785, .135, .15, .86) .1s
	}
}

@media (max-width:750px) {
	body.noTrans .header_menu {
		visibility: hidden;
		clip-path: inset(0 0 0 100%);
		-webkit-clip-path: inset(0 0 0 100%);
		transition: none!important
	}
}

.header_container {
	position: relative;
	width: 100%;
	transition: all .3s ease
}

.header_container.hide {
	opacity: 0;
	transition: all .3s ease
}

@media (max-width:750px) {
	.header_container {
		padding: 25px 32px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-align: center;
		align-items: center
	}
}

.header_logo {
	position: absolute;
	top: -5px;
	left: 0
}

@media (max-width:750px) {
	.header_logo {
		position: static;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		justify-items: center
	}
	.header_logo path,
	.header_logo polygon,
	.header_logo polyline {
		transition: all .9s ease
	}
}

.header_logo:after {
	content: "";
	display: block;
	height: 3px;
	width: 100%;
	position: absolute;
	bottom: -5px;
	right: 0;
	left: 0;
	background-color: transparent;
	transition: transform .9s cubic-bezier(.82, .01, .98, .27);
	background-color: var(--main-bg-color);
	transform-origin: left;
	transform: scaleX(0)
}

@media (max-width:1500px) {
	.header_logo:after {
		bottom: 0
	}
}

.header_logo.active:after {
	transform: scaleX(1);
	transition: transform 2s cubic-bezier(.82, .01, .98, .27)
}

.header_burger,
.header_close {
	width: 22px;
	height: auto;
	display: none
}

@media (max-width:750px) {
	.header_burger,
	.header_close {
		display: block
	}
}

.header_logo_img {
	width: 209px
}

.header_logo_imgClip {
	position: absolute;
	top: 0;
	left: 0;
	width: 209px;
	-webkit-clip-path: inset(0 100% 0 0);
	clip-path: inset(0 100% 0 0);
	transition: all .9s cubic-bezier(.82, .01, .98, .27)
}

.header_logo_imgClip path,
.header_logo_imgClip polygon,
.header_logo_imgClip polyline {
	fill: var(--logo-fill);
	transition: all .9s ease
}

.header_logo_imgClip.active {
	transition: all 2s cubic-bezier(0, 0, 0.27, 0.61); /* cubic-bezier(0.2,-0.01, 0.98, 0.27) */
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0)
}

.header_menu {
	position: absolute;
	right: 0;
	top: 0
}

.header_menu ul {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center
}

.header_menu li {
	margin-right: 55px
}

@media (max-width:850px) {
	.header_menu li {
		margin-right: 35px
	}
}

.header_menu li:last-child {
	margin-right: 0
}

.header_menu li.active a {
	color: var(--main-bg-color)
}

.header_menu a {
	font-family: Euclid Circular B;
	font-size: 20px;
	font-weight: 500;
	color: #000
}

@media (max-width:1500px) {
	.header_menu a {
		font-size: 16px
	}
}

@media (max-width:750px) {
	.header_menu {
		visibility: hidden;
		clip-path: inset(0 0 0 100%);
		-webkit-clip-path: inset(0 0 0 100%);
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: var(--main-bg-color);
		z-index: 9999;
		padding: 0 32px;
		transition: visibility 0s 0s, all .5s cubic-bezier(.785, .135, .15, .86) .1s
	}
	.header_menu ul {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-align: start;
		align-items: flex-start
	}
	.header_menu li {
		margin: 25px 0
	}
	.header_menu li.active a {
		color: #fff
	}
	.header_menu a {
		font-size: 30px;
		color: #fff!important
	}
}

@media (max-width:750px) {
	.header_menu_container {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 32px
	}
}

.header_menu_top {
	width: 100%;
	padding: 25px 0;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
	display: none;
	margin-bottom: 100px
}

@media (max-width:750px) {
	.header_menu_top {
		margin-bottom: 0;
		display: -ms-flexbox;
		display: flex
	}
}

.header {
	transform: translateY(0);
	opacity: 1;
	transition: transform 1.2s ease, opacity 1.6s ease;
	will-change: transform, opacity
}

@media (max-width:750px) {
	.header_logo_img {
		width: 160px
	}
	.header_logo_imgClip {
		display: none;
	}
}
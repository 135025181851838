 .video_frame {
    width: 800px;
    height: 500px;
}



@media (max-width: 1220px) {
    .video_frame {
        width: 600px;
        height: 400px;
    }
    
}

@media (max-width: 900px) {
    .video_frame {
        width: 400px;
        height: 300px;
    }
}

@media (max-width: 768px) {
    .video_frame {
        width: auto;
        height: auto;
    }
}


/*
@media (max-width: 1220px) {
    .video_frame {
        width: 600px;
        height: 400px;
    }
    
}

 */
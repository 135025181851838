.landing_section {
    width: 100%;
    padding-top: 220px;
    padding-bottom: 0;
}

@media (max-width: 1600px) {
    .landing_section {
        padding-top: 200px;
    }
}

@media (max-width: 1500px) {
    .landing_section {
        padding-top: 180px;
        padding-bottom: 100px;
    }
}

@media (max-width: 750px) {
    .landing_section {
        padding-top: 120px;
        padding-bottom: 75px;
    }
}

.landing_container {
    width: 100%;
    position: relative;
}

.container_content {
    max-width: 1280px;
}

@media (max-width: 750px) {
    .container_content {
        width: 100%;
        padding: 0 32px;
    }
}

@media (max-width: 1050px) {
    .container_content {
        max-width: 650px;
        padding: 0 32px;
    }
}

@media (max-width: 1500px) {
    .container_content {
        max-width: 845px;
        padding: 0 32px;
    }
}

@media (max-width: 1600px) {
    .container_content {
        max-width: 1100px;
    }
}

.landing_main_container {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: start;
    align-items: flex-start;
}

@media (max-width: 750px) {
    .landing_main_container {
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.landing_left {
    width: calc(50% - 12px);
    z-index: 2;
}

@media (max-width: 750px) {
    .landing_left {
        width: 100%;
    }
}

.landing_left p,
.select {
    font-family: Euclid Circular B;
    font-size: 95px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -5.1px;
    color: #000;
    max-width: 520px;
}

@media (max-width: 1500px) {

    .landing_left p,
    .select {
        font-size: 45px;
        letter-spacing: -2.41px;
    }
}

.landing_right {
    width: calc(50% - 12px);
    margin-top: 120px;
    z-index: 2;
}


@media (max-width: 1500px) {
    .landing_right {
        margin-top: 80px;
    }
}

@media (max-width: 1050px) {
    .landing_right {
        width: 55%;
        margin-top: 70px;
    }
}

@media (max-width: 750px) {
    .landing_right {
        width: 100%;
        margin-top: 57px;
    }
}


.landing_right h1 {
    font-family: Euclid Circular B;
    font-size: 30px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color: #7a7a7a;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease, opacity 1.6s ease;
    transition-delay: .6s;
    will-change: transform, opacity;
}

@media (max-width: 1500px) {
    .landing_right h1 {
        font-size: 20px;
        letter-spacing: -.48px;
    }
}

.select {
    /*  background-color: var(--main-bg-color1); */
    position: relative;
    color: var(--main-bg-color1);
    padding: 10px 4px;
}

input.select {
    width: 100%;
}

@media (max-width: 750px) {
    .select {
        height: auto;
        padding: 0px 2px;
    }
}

:root {
  --blue: #3625d3;
  --secondary: #666;
  --subtitle-color: #59595e;
  --border-color: #d8d8d8;
}

.career-container {
  max-width: 1200px;
  margin: 60px auto 80px;
  padding: 0 60px;
}

.narrow {
  max-width: 1000px;
}

.hero_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 220px;
  padding-bottom: 40px;
}

.hero_heading {
  font-size: 3.5em;
  text-align: center;
  margin-bottom: 0.5rem;
}

.hero_text {
  color: var(--secondary);
}
.text {
  font-size: 23px;
  line-height: 2.5rem;
}

.image {
  margin-bottom: 5rem;
}

.mb {
  margin-bottom: 5rem;
}

.company_features {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.company_features > li {
  padding: 1rem;
  font-weight: 300;
  font-size: 1.2rem;
}

.company_features li ::before {
  flex-shrink: 0;
  content: "";
  display: inline-block;
  margin-top: 0.45em;
  margin-right: 0.7em;
  width: 17px;
  height: 12px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 12'%3E%3Cpath d='M17 2.06L7.06 12l-1.41-1.45-5.3-5.3 1.42-1.41 5.29 5.29L15.55.65z' fill='%2342f'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.job_header {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 40px;
}
.job_container {
  display: flex;
  margin-bottom: 5rem;
}
.dw-career .content {
  padding: 0;
  flex-grow: 1;
}

.aside {
  width: 220px;
  margin-right: 2rem;
}

.side_nav_list {
  list-style: none;
  color: var(--blue);
  font-size: 1.2rem;
  font-weight: 600;
}
.side_nav_list > li {
  padding: 1rem;
  border-radius: 3px;
  cursor: pointer;
}
.side_nav_list > li:hover {
  background-color: #3625d323 !important;
  color: black !important;
}

.active {
  background-color: #3625d323;
  color: black;
}

.job {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 2rem 0;
  font-size: 1.2rem;
}

.location {
  padding-left: 200px;
}

.right {
  flex-grow: 1;
}
.job_item {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}
.job_item > a {
  color: var(--blue);
  text-decoration: none;
  /* font-size: 1rem; */
}

.job_item > a:hover {
  color: #2a1d97;
}

.image {
  width: 100%;
}
.side_nav_select {
  display: none;
}

.requirements {
  list-style-type: disc;
  padding-left: 30px;
}
.jobs {
  margin: 32px 0px 0px;
}
.jobslist {
  padding: 18px;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
}
.role-details {
  display: flex;
  align-items: center;
}
.role-name {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 12px 0px;
  color: #000;
}
.divider {
  padding: 0px 6px;
}
.pay {
  font-size: 12px;
  padding: 8px 0px;
  color: #000;
}
.status-condition {
  font-size: 10px;
  text-align: right;
}

.heading {
  font-size: 1.7rem;
  font-weight: 400;
  padding-bottom: 10px;
}
.subtitle {
  font-size: 10px;
  color: gray;
  text-align: right;
}

.slick-dots li {
  background-color: rgb(165, 165, 165);
  width: 2px !important;
  height: 2px !important;
  /* border-radius: 100% !important; */
  border-radius: 16px;
}

.slick-dots li.slick-active {
  background-color: rgb(31, 31, 31) !important;
}

.slider-container {
  margin: auto 120px;
}

.slider-image-container {
  height: 100%;
  width: 100%;
}

.slider-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.no-opening {
  text-align: center;
  margin-top: 42px;
}

.explain-bar {
  margin-top: 20px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  display: flex;
  height: 80px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
}
.explain-title {
  color: var(--subtitle-color);
  font-size: 0.8rem;
  white-space: nowrap;
}
.explain-subtitle {
  font-size: 1.1rem;
  font-weight: 500;
  white-space: nowrap;
}

.explain-contents + .explain-contents {
  border-left: 1px solid var(--border-color);
  padding-left: 16px;
}

.ql-editor h3 {
  font-size: 1.5rem;
  font-weight: 500;
  padding-bottom: 10px;
}

.ql-editor li {
  font-size: 1.1rem;
  line-height: 2.5rem;
  margin-left: 12px;
}

.ql-editor li::marker {
  color: var(--main-bg-color);
  content: "🤖 ";
}

@media screen and (max-width: 990px) {
  .explain-contents,
  .explain-bar {
    height: auto;
  }
  .explain-bar {
    flex-wrap: wrap;
    padding-bottom: 14px;
  }
  .explain-contents {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .explain-contents + .explain-contents {
    padding: 0;
    border: 0;
  }

  .job-subtitle-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .job-subtitle-wrapper .posted {
    margin-left: 0;
    margin-top: 6px;
  }
}

@media only screen and (max-width: 950px) {
  .career-container {
    max-width: 100vw;
    /* padding: 0 20px; */
  }
  .hero_heading {
    font-size: 2.2em;
  }
  .slider-container {
    margin: auto;
  }
  .text {
    padding-top: 20px;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
  .hero_container {
    padding-top: 80px;
  }
  .company_features {
    grid-template-columns: 1fr 1fr;
  }
  .job_header {
    font-size: 2em;
  }
  .side_nav_list {
    display: none;
  }
  .side_nav_select {
    display: block;
  }
  .job_container {
    flex-direction: column;
  }
  .aside {
    width: 100%;
  }
  .aside .side_nav_select {
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    border-color: grey;
    color: grey;
    margin-bottom: 1rem;
    height: auto;
    border: 1px solid;
  }
  .company_features > li {
    padding: 0.8rem;
  }
  .company_features {
    grid-template-columns: 1fr;
  }
  .block_text {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .job {
    flex-direction: column;
  }
  .left {
    margin-bottom: 1rem;
  }

  .career-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .role-details {
    flex-flow: column;
    align-items: normal;
  }
  .divider {
    display: none;
  }
  .posted {
    text-align: left;
  }
}

.job-overview-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.callout {
  position: relative;
  transition: 0.3s cubic-bezier(0.65, 0.21, 0.6, 0.87);
  padding: 1.5rem;
  margin-top: 5px;
  margin-bottom: 5px;
}
@media print, screen and (min-width: 64em) {
  .callout {
    padding: 70px 100px;
  }
}
.callout.purple {
  color: #fff8e8;
  background: var(--main-bg-color);
}
@media print, screen and (min-width: 64em) {
  .callout.purple {
    background: none;
    color: #fff8e8;
  }
}
.callout h3 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fd2f46;
  transition: 0.3s cubic-bezier(0.65, 0.21, 0.6, 0.87);
}
.callout p {
  margin-bottom: 0;
}
.callout p span {
  margin-left: 20px;
}
.callout .shapes {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  height: 100%;
  overflow: visible;
}
.callout .shapes path {
  transition: 0.3s cubic-bezier(0.65, 0.21, 0.6, 0.87);
}
.callout:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  background: var(--main-bg-color);
  z-index: -1;
  transition: 0.3s cubic-bezier(0.65, 0.21, 0.6, 0.87);
}
@media print, screen and (min-width: 64em) {
  .callout:hover h3 {
    color: #fff8e8;
  }
  .callout:hover:after {
    left: -500px;
    right: -500px;
    background: #fd2f46;
  }
  .callout:hover .shape2 {
    transform: translateX(10px);
  }
  .callout:hover .shape2 {
    transform: translateX(50px);
  }
  .callout:hover .shape3 {
    transform: translateX(100px);
  }
}

@media only screen and (max-width: 950px) {
  .callout h3 {
    font-size: 1.3rem;
  }
}

.landing1_container {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
}

.landing1_container .landing1_left {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    flex-basis: 50%;
}


.landing1_content {
    align-items: flex-end;
    font-family: Euclid Circular B;
    font-size: 28px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color:
        #7a7a7a;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -webkit-transition: opacity 1.6s ease, -webkit-transform 1.2s ease;
    transition: opacity 1.6s ease, -webkit-transform 1.2s ease;
    transition: transform 1.2s ease, opacity 1.6s ease;
    transition: transform 1.2s ease, opacity 1.6s ease, -webkit-transform 1.2s ease;
    transition-delay: 0s, 0s, 0s;
    -webkit-transition-delay: .6s;
    transition-delay: .6s;
    will-change: transform, opacity;
    padding-top: 50px;
}

.landing1_title {
    font-size: 70px;
    line-height: 70px;
    font-weight: 500;
    color: #3F0D7A
}

.landing1_right {
    user-select: none;
    display: flex;
    flex-direction: row;
    flex-basis: 60%;
    padding-left: 60px;
    justify-content: center;
    height: 70vh;
}

.landing1_right_item1 {
    height: 100%;
    width: 100%;
    border-right: 2px solid black;
    overflow: hidden;
    position: relative;
}


.landing1_right_item2 {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}



.landing1_right_item1 img {
    position: absolute;
    border-radius: 50%;
    left: 25%;
    z-index: 1;
    top: 30%;
    height: 320px;
    width: 320px;
    overflow: hidden;
    animation: move_two 15s ease infinite;
    animation-delay: 0s;
    animation-delay: 2s;

}


.landing1_right_item2 img {
    position: absolute;
    border-radius: 50%;
    right: 50%;
    z-index: 1;
    top: 10%;
    height: 320px;
    width: 320px;
    overflow: hidden;
    animation: move_two 15s ease infinite;
    animation-delay: 0s;
    animation-delay: 2s;

}

.landing1_right_letter1 {
    font-size: 210px;
    font-weight: bold;
    position: absolute;
    right: 30%;
    margin: 0;
    color: #3F0D7A;
    overflow: hidden;
}

.landing1_right_letter2 {
    font-size: 210px;
    font-weight: bold;
    position: absolute;
    right: 10%;
    bottom: 0;
    margin: 0;
    color: #3F0D7A;
    overflow: hidden;
}

@media (max-width:900px) {
    .landing1_right {
        display: none;
    }

    .landing1_container .landing1_left {
        flex-basis: 100%;
    }

}


@media (max-width:1500px) {
    .landing1_content h1 {
        font-size: 20px;
        letter-spacing: -.48px
    }
}
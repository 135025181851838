.slick-list,.slick-track {
    height: 100%
}

.slick-track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.partners_title {
    width: 1340px;
    margin: 0 auto;
    box-sizing: border-box;
    height: auto;
    padding-top : 50px;
    position: relative;
    z-index: 3;
    -webkit-transition: -webkit-transform 1s cubic-bezier(.19,1,.22,1);
    transition: -webkit-transform 1s cubic-bezier(.19,1,.22,1);
    transition: transform 1s cubic-bezier(.19,1,.22,1);
    transition: transform 1s cubic-bezier(.19,1,.22,1),-webkit-transform 1s cubic-bezier(.19,1,.22,1)
}

.partners_title_text {
    font-family: Euclid Circular B;
    font-size: 30px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color: #505050;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease,opacity 1.6s ease;
    transition-delay: .6s;
    will-change: transform,opacity;
    
}

@media (max-width: 1740px) {
    .partners_title {
        display: inline;
        padding-top:30px;
        padding-right:10px;
    }
    .partners_title_text {
       font-size: 25px;
       padding-left: 30px;
    }
}


@media (max-width: 1223px) {
    .partners_title {
        display: inline;
        padding-top:20px;
        padding-right:10px;
    }
    .partners_title_text {
       font-size: 25px;
       padding-left: 20px;
    }
}

@media (max-width: 750px) {
    .partners_title {
        display: inline;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        height: auto;
        padding-left: 20px;
        padding-top : 10px;
        position: relative;
        z-index: 3;
    }
    .partners_title_text {
       font-size: 25px;
       padding-left: 10px;
    }
}


/* .section-partners .partners:after {
    position: absolute;
    top: 0;
    right: 140px;
    display: block;
    width: 2px;
    height: 240px;
    content: "";
    z-index: 2
}

@media (max-width: 1740px) {
    .section-partners .partners:after {
        right:100px;
        height: 140px
    }
}

@media (max-width: 1223px) {
    .section-partners .partners:after {
        display:none
    }
} */

.section-partners .partners .section-progress {
    top: 240px;

}

@media (max-width: 1740px) {
    .section-partners .partners .section-progress {
        top:140px
    }
}

.section-partners {
    padding-bottom: 0
}

@media (max-width: 767px) {
    .section-partners {
        padding-top:0
    }
}

.section-partners .section-index {
    top: 50px;
    right: calc(50vw - 720px)
}

@media (max-width: 1740px) {
    .section-partners .section-index {
        right:calc(50vw - 512px)
    }
}

.section-partners .wrapper {
    padding-bottom: 200px;
    position: relative
}

@media (max-width: 1740px) {
    .section-partners .wrapper {
        padding-bottom:100px
    }
}

.section-partners .partners-visual-01 {
    position: absolute;
    pointer-events: none;
    top: 100px;
    right: 50%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: 140px
}

@media (max-width: 1740px) {
    .section-partners .partners-visual-01 {
        width:100px!important;
        top: 82px
    }
}

@media (max-width: 767px) {
    .section-partners .partners-visual-01 {
        left:0
    }
}

.section-partners .partners {
    position: relative;
    padding-bottom: 100px
}

@media (max-width: 1223px) {
    .section-partners .partners {
        padding-bottom:50px
    }
}

@media (max-width: 767px) {
    .section-partners .partners {
        padding-bottom:0
    }
}

.section-partners .partners:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 240px;
    display: block;
    content: "";
}

@media (max-width: 1740px) {
    .section-partners .partners:before {
        height:140px
    }
}

@media (max-width: 767px) {
    .section-partners .partners:before {
        display:none
    }
}

.section-partners .partners__content {
    position: relative;
    z-index: 2;
    width: 1440px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 240px ;
  /*   background: #1e1541 */
}


@media (max-width: 1740px) {
    .section-partners .partners__content {
        width:1024px;
        padding: 80px
    }
}

@media (max-width: 1223px) {
    .section-partners .partners__content {
        width:100%;
        padding: 50px calc((100% - 568px) / 2)
    }
}


@media (max-width: 767px) {
    .section-partners .partners__content {
        padding:20px 30px
    }
}

.section-partners .partners__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* border: 1px solid #382879; */
    border: 0px solid var(--client-border-color);
}

.section-partners .partners .slick-dots {
    height: 240px
}

@media (max-width: 1740px) {
    .section-partners .partners .slick-dots {
        height:140px
    }
}

@media (max-width: 767px) {
    .section-partners .partners .slick-dots {
        height:100px
    }
}

.section-partners .partner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    height: 160px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
}

@media (max-width: 1223px) {
    .section-partners .partner {
        height:142px
    }
}

@media (max-width: 767px) {
    .section-partners .partner {
        width:50%;
        height: calc(50vw - 30px)
    }
}

.section-partners .partner img {
    max-width: 60%;
    max-height: 40%
}

@media (max-width: 1223px) {
    .section-partners .partner img {
        max-width:60%
    }
}

.section-partners .partner .left-top {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 1;
    overflow: hidden
}

.section-partners .partner .left-top:after,.section-partners .partner .left-top:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: var(--client-border-color);
    -webkit-transition: -webkit-transform 1s cubic-bezier(.19,1,.22,1);
    transition: -webkit-transform 1s cubic-bezier(.19,1,.22,1);
    transition: transform 1s cubic-bezier(.19,1,.22,1);
    transition: transform 1s cubic-bezier(.19,1,.22,1),-webkit-transform 1s cubic-bezier(.19,1,.22,1)
}

.section-partners .partner .left-top:before {
    width: 100%;
    height: 2px;
    -webkit-transform: translateX(-110%);
    transform: translateX(-110%)
}

.section-partners .partner .left-top:after {
    width: 2px;
    height: 100%;
    -webkit-transform: translateY(-110%);
    transform: translateY(-110%)
}

.section-partners .partner .right-bottom {
    pointer-events: none;
    bottom: -2px;
    right: -2px;
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 1;
    overflow: hidden
}

.section-partners .partner .right-bottom:after,.section-partners .partner .right-bottom:before {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    content: "";
    background: var(--client-border-color);
    -webkit-transition: -webkit-transform 1s cubic-bezier(.19,1,.22,1);
    transition: -webkit-transform 1s cubic-bezier(.19,1,.22,1);
    transition: transform 1s cubic-bezier(.19,1,.22,1);
    transition: transform 1s cubic-bezier(.19,1,.22,1),-webkit-transform 1s cubic-bezier(.19,1,.22,1)
}

.section-partners .partner .right-bottom:before {
    width: 100%;
    height: 2px;
    -webkit-transform: translateX(110%);
    transform: translateX(110%)
}

.section-partners .partner .right-bottom:after {
    width: 2px;
    height: 100%;
    -webkit-transform: translateY(110%);
    transform: translateY(110%)
}

.section-partners .partner:hover .left-top:after,.section-partners .partner:hover .left-top:before,.section-partners .partner:hover .right-bottom:after,.section-partners .partner:hover .right-bottom:before {
    -webkit-transform: none;
    transform: none
}

.section-partners .partner:after {
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* border: 1px solid #382879 */
    border: 0px solid var(--client-border-color)
}

.partners .partner__image {
    -webkit-filter:saturate(0); /* Safari 6.0 - 9.0 */
    filter: saturate(0);
}



.partner:hover img {
    -webkit-filter:saturate(1); /* Safari 6.0 - 9.0 */
    filter: saturate(1);
}
.partners__list {
    width: 960px;
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 999;
    opacity: 1;
}
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,700);
.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 400ms ease-in;
}


.link_menu a.active{
    background-color: var(--anchor-bg-color);
    padding: 5px;
    color: var(--main-bg-color) !important;
}

.link_menu a {
    transition: .5s;
}

.link_menu a:hover{
    background-color: var(--anchor-bg-color);
    color:#000
}


::selection {
    background: var(--main-selection);
    color: var(--main-selection-text)
}

::-moz-selection {
    background: var(--main-selection);
    color: var(--main-selection-text)
}

.header {
	position: fixed;
	left: 80px;
	right: 80px;
	top: 80px;
	z-index: 1000;
	transition: all .5s ease
}

@media (max-width:1600px) {
	.header {
		left: 50px;
		right: 50px;
		top: 50px
	}
}

.header.hideAnimation {
	pointer-events: none;
	opacity: 0;
	transition: all .5s ease
}

@media (max-width:1600px) {
	.header {
		left: 50px;
		right: 50px
	}
}

@media (max-width:750px) {
	.header {
		/* opacity: 1!important; commented since we also need to hide it in mobile view*/
		width: 100%;
		top: 0;
		left: 0
	}
}

body.openMobileMenu .header_menu {
	clip-path: inset(0 0 0 0);
	-webkit-clip-path: inset(0 0 0 0);
	visibility: visible;
	transition: visibility 0s 0s, all .5s cubic-bezier(.785, .135, .15, .86) .1s
}

body.openMobileMenu .header_socials {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s 0s, all .5s ease .1s
}

body.openMobileMenu .header_logo path,
body.openMobileMenu .header_logo polygon,
body.openMobileMenu .header_logo polyline {
	fill: #fff;
	transition: all .9s ease
}

body.openMobileMenu .header_contactBtn {
	opacity: 0;
	transition: all .5s ease
}

@media (max-width:750px) {
	body.mobileMenuTrans .header_menu {
		clip-path: inset(0 0 100% 0);
		-webkit-clip-path: inset(0 0 100% 0);
		visibility: visible;
		transition: visibility 0s 0s, all .7s cubic-bezier(.785, .135, .15, .86) .1s
	}
}

@media (max-width:750px) {
	body.noTrans .header_menu {
		visibility: hidden;
		clip-path: inset(0 0 0 100%);
		-webkit-clip-path: inset(0 0 0 100%);
		transition: none!important
	}
}

.header_container {
	position: relative;
	width: 100%;
	transition: all .3s ease
}

.header_container.hide {
	opacity: 0;
	transition: all .3s ease
}

@media (max-width:750px) {
	.header_container {
		padding: 25px 32px;
		display: flex;
		justify-content: space-between;
		align-items: center
	}
}

.header_logo {
	position: absolute;
	top: -5px;
	left: 0
}

@media (max-width:750px) {
	.header_logo {
		position: static;
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: center;
		justify-items: center
	}
	.header_logo path,
	.header_logo polygon,
	.header_logo polyline {
		transition: all .9s ease
	}
}

.header_logo:after {
	content: "";
	display: block;
	height: 3px;
	width: 100%;
	position: absolute;
	bottom: -5px;
	right: 0;
	left: 0;
	background-color: transparent;
	transition: transform .9s cubic-bezier(.82, .01, .98, .27);
	background-color: var(--main-bg-color);
	transform-origin: left;
	transform: scaleX(0)
}

@media (max-width:1500px) {
	.header_logo:after {
		bottom: 0
	}
}

.header_logo.active:after {
	transform: scaleX(1);
	transition: transform 2s cubic-bezier(.82, .01, .98, .27)
}

.header_burger,
.header_close {
	width: 22px;
	height: auto;
	display: none
}

@media (max-width:750px) {
	.header_burger,
	.header_close {
		display: block
	}
}

.header_logo_img {
	width: 209px
}

.header_logo_imgClip {
	position: absolute;
	top: 0;
	left: 0;
	width: 209px;
	-webkit-clip-path: inset(0 100% 0 0);
	clip-path: inset(0 100% 0 0);
	transition: all .9s cubic-bezier(.82, .01, .98, .27)
}

.header_logo_imgClip path,
.header_logo_imgClip polygon,
.header_logo_imgClip polyline {
	fill: var(--logo-fill);
	transition: all .9s ease
}

.header_logo_imgClip.active {
	transition: all 2s cubic-bezier(0, 0, 0.27, 0.61); /* cubic-bezier(0.2,-0.01, 0.98, 0.27) */
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0)
}

.header_menu {
	position: absolute;
	right: 0;
	top: 0
}

.header_menu ul {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center
}

.header_menu li {
	margin-right: 55px
}

@media (max-width:850px) {
	.header_menu li {
		margin-right: 35px
	}
}

.header_menu li:last-child {
	margin-right: 0
}

.header_menu li.active a {
	color: var(--main-bg-color)
}

.header_menu a {
	font-family: Euclid Circular B;
	font-size: 20px;
	font-weight: 500;
	color: #000
}

@media (max-width:1500px) {
	.header_menu a {
		font-size: 16px
	}
}

@media (max-width:750px) {
	.header_menu {
		visibility: hidden;
		clip-path: inset(0 0 0 100%);
		-webkit-clip-path: inset(0 0 0 100%);
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: var(--main-bg-color);
		z-index: 9999;
		padding: 0 32px;
		transition: visibility 0s 0s, all .5s cubic-bezier(.785, .135, .15, .86) .1s
	}
	.header_menu ul {
		flex-direction: column;
		align-items: flex-start
	}
	.header_menu li {
		margin: 25px 0
	}
	.header_menu li.active a {
		color: #fff
	}
	.header_menu a {
		font-size: 30px;
		color: #fff!important
	}
}

@media (max-width:750px) {
	.header_menu_container {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 32px
	}
}

.header_menu_top {
	width: 100%;
	padding: 25px 0;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	display: none;
	margin-bottom: 100px
}

@media (max-width:750px) {
	.header_menu_top {
		margin-bottom: 0;
		display: flex
	}
}

.header {
	transform: translateY(0);
	opacity: 1;
	transition: transform 1.2s ease, opacity 1.6s ease;
	will-change: transform, opacity
}

@media (max-width:750px) {
	.header_logo_img {
		width: 160px
	}
	.header_logo_imgClip {
		display: none;
	}
}
.footer {
    height: auto;
    padding: 100px 0;
    margin-top: 50px;
    align-items: center;
    transition: all .3s ease;
    overflow: hidden;
    z-index: 2;
    color: #fff !important;
}

.footer,
.footer_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative
}

.footer_container {
    align-items: flex-start
}

@media (max-width: 750px) {
    .footer_container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
    }
}

.container_content {
    max-width: 1280px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.footer_contact {
    width: 50%;
    position: relative;
    top: -18px;
}


@media (max-width: 750px) {
    .footer_contact {
        width: 100%;
        margin-bottom: 0;
        top: auto;
    }
}

.footer_contact_link {
    color: inherit;
    outline: none;
    text-decoration: none;
    color: #fff;
    transition: all .5s ease;
}

.footer_contact_link {
    fill: var(--main-bg-color);
    stroke: #fff;
}

.footer_contact_link_text {
    font-family: Euclid Circular B;
    font-size: 95px;
    font-weight: 500;
    letter-spacing: -5.1px;
    max-width: 400px;
    transition: all 2s ease !important;
}

.footer_contact_link_text:hover {
    -webkit-text-stroke: 1.0px #fff;
    -webkit-text-fill-color: var(--main-bg-color);
}

@media (max-width: 750px) {
    .footer_contact_link_text {
        width: 100%;
        margin-bottom: 40px;
        top: auto
    }
}

@media (max-width: 1500px) {
    .footer_contact_link_text {
        font-size: 64px;
        max-width: 300px
    }
}

@media (max-width: 750px) {
    .footer_contact_link_text {
        max-width: 250px;
        font-size: 45px;
        letter-spacing: -2.41px
    }
}

.footer_navigation {
    width: calc(25% - 12px);
    margin-left: 12px;
}

.footer_info {
    width: 25%;
}

@media (max-width: 750px) {
    .footer_navigation {
        width: 40%;
        margin-left: 5px;
    }

    .footer_info {
        width: 50%;
    }
}



.footer_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--main-bg-color);
    width: 100%;
    height: 100vh;
    z-index: -1;
    transform: translate3d(0, 100%, 0);
    transition: transform .9s cubic-bezier(.785, .135, .15, .86)
}

@media (max-width: 750px) {
    /* .footer_wrapper {
        ;
        top: auto;
        bottom: 0
    } */
}

.footer.active .footer_wrapper {
    transform: translateZ(0);
    transition: transform .9s cubic-bezier(.785, .135, .15, .86)
}

.footer.active .footer_clip {
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(0 0 0 0);
    transition: all .9s cubic-bezier(.785, .135, .15, .86)
}

.footer_navigation li {
    padding-bottom: 20px;
    font-size: 22px;
    opacity: .6;
    transition: all .3s ease;
    cursor: pointer;
}

.footer_navigation li:hover {
    opacity: 1;
}

.footer_navigation_title,
.footer_info_title {
    font-size: 30px;
    padding-bottom: 25px;
    cursor: default;
}

.footer_info_content {
    line-height: 30px;
    font-size: 20px;
    opacity: .6;
    transition: all .3s ease;
    cursor: text;
}

@media (max-width: 750px) {
    .footer_info_content {
        font-size: 16px;
        line-height: 25px;
    }
}

.footer_socials {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0
}

.footer_socials_right {
    display: block;
    position: absolute;
    bottom: 20px;
    right: 0
}


.footer_socials_right span {
    padding: 0px 50px;
    opacity: .6;
}


.footer_socials li {
    list-style-type: none;
    display: inline;
    margin: 5px;
    padding: 0px 50px;
}

.footer_socials_list a {
    opacity: .6;
    transition: all .3s ease;
    cursor: pointer;
}

.footer_socials_list a:hover {
    opacity: 1;
}



@media (max-width: 750px) {
    .footer_socials li {
        padding: 0px 7px;
        margin: 0;
        font-size: 13px;
    }

    .footer_socials_right span {
        padding: 0px 7px;
        margin: 0;
        font-size: 9px;
    }


    .footer_socials_list a::after {
        content: "";
        padding: 0px 5px;
        border-right: 2px solid white;
    }
}

.social_icon {
    width: 20px;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
}
.dw-cursor {
    display: none;
    position: fixed;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    z-index: 99999;
    color: inherit;
    border-radius: 50%;
    border: 0 solid;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    filter: saturate(2);
    opacity: .8;
    pointer-events: none;
    transition: color .5s,width .3s,height .3s,margin .3s,border-width .5s;
    will-change: color,width,height,margin,border-with,transform
}

@media (min-width: 1280px) {
    .dw-cursor {
        display:block
    }
}

.dw-cursor.-visible {
    margin: -23px 0 0 -23px;
    width: 46px;
    height: 46px;
    border-width: 2px
}

.dw-cursor.-mousedown {
    margin: -18px 0 0 -18px;
    width: 36px;
    height: 36px
}

.dw-cursor.-move {
    width: 34px;
    height: 34px
}

.dw-cursor.-disabled,.dw-cursor.-hidden {
    margin: 0;
    width: 0;
    height: 0;
    border-width: 0
}

:root {
  --blue: #3625d3;
  --secondary: #666;
  --subtitle-color: #59595e;
  --border-color: #d8d8d8;
}

.career-container {
  max-width: 1200px;
  margin: 60px auto 80px;
  padding: 0 60px;
}

.narrow {
  max-width: 1000px;
}

.hero_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 220px;
  padding-bottom: 40px;
}

.hero_heading {
  font-size: 3.5em;
  text-align: center;
  margin-bottom: 0.5rem;
}

.hero_text {
  color: var(--secondary);
}
.text {
  font-size: 23px;
  line-height: 2.5rem;
}

.image {
  margin-bottom: 5rem;
}

.mb {
  margin-bottom: 5rem;
}

.company_features {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.company_features > li {
  padding: 1rem;
  font-weight: 300;
  font-size: 1.2rem;
}

.company_features li ::before {
  flex-shrink: 0;
  content: "";
  display: inline-block;
  margin-top: 0.45em;
  margin-right: 0.7em;
  width: 17px;
  height: 12px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 12'%3E%3Cpath d='M17 2.06L7.06 12l-1.41-1.45-5.3-5.3 1.42-1.41 5.29 5.29L15.55.65z' fill='%2342f'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.job_header {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 40px;
}
.job_container {
  display: flex;
  margin-bottom: 5rem;
}
.dw-career .content {
  padding: 0;
  flex-grow: 1;
}

.aside {
  width: 220px;
  margin-right: 2rem;
}

.side_nav_list {
  list-style: none;
  color: var(--blue);
  font-size: 1.2rem;
  font-weight: 600;
}
.side_nav_list > li {
  padding: 1rem;
  border-radius: 3px;
  cursor: pointer;
}
.side_nav_list > li:hover {
  background-color: #3625d323 !important;
  color: black !important;
}

.active {
  background-color: #3625d323;
  color: black;
}

.job {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 2rem 0;
  font-size: 1.2rem;
}

.location {
  padding-left: 200px;
}

.right {
  flex-grow: 1;
}
.job_item {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}
.job_item > a {
  color: var(--blue);
  text-decoration: none;
  /* font-size: 1rem; */
}

.job_item > a:hover {
  color: #2a1d97;
}

.image {
  width: 100%;
}
.side_nav_select {
  display: none;
}

.requirements {
  list-style-type: disc;
  padding-left: 30px;
}
.jobs {
  margin: 32px 0px 0px;
}
.jobslist {
  padding: 18px;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
}
.role-details {
  display: flex;
  align-items: center;
}
.role-name {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 12px 0px;
  color: #000;
}
.divider {
  padding: 0px 6px;
}
.pay {
  font-size: 12px;
  padding: 8px 0px;
  color: #000;
}
.status-condition {
  font-size: 10px;
  text-align: right;
}

.heading {
  font-size: 1.7rem;
  font-weight: 400;
  padding-bottom: 10px;
}
.subtitle {
  font-size: 10px;
  color: gray;
  text-align: right;
}

.slick-dots li {
  background-color: rgb(165, 165, 165);
  width: 2px !important;
  height: 2px !important;
  /* border-radius: 100% !important; */
  border-radius: 16px;
}

.slick-dots li.slick-active {
  background-color: rgb(31, 31, 31) !important;
}

.slider-container {
  margin: auto 120px;
}

.slider-image-container {
  height: 100%;
  width: 100%;
}

.slider-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.no-opening {
  text-align: center;
  margin-top: 42px;
}

.explain-bar {
  margin-top: 20px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  display: flex;
  height: 80px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
}
.explain-title {
  color: var(--subtitle-color);
  font-size: 0.8rem;
  white-space: nowrap;
}
.explain-subtitle {
  font-size: 1.1rem;
  font-weight: 500;
  white-space: nowrap;
}

.explain-contents + .explain-contents {
  border-left: 1px solid var(--border-color);
  padding-left: 16px;
}

.ql-editor h3 {
  font-size: 1.5rem;
  font-weight: 500;
  padding-bottom: 10px;
}

.ql-editor li {
  font-size: 1.1rem;
  line-height: 2.5rem;
  margin-left: 12px;
}

.ql-editor li::marker {
  color: var(--main-bg-color);
  content: "🤖 ";
}

@media screen and (max-width: 990px) {
  .explain-contents,
  .explain-bar {
    height: auto;
  }
  .explain-bar {
    flex-wrap: wrap;
    padding-bottom: 14px;
  }
  .explain-contents {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .explain-contents + .explain-contents {
    padding: 0;
    border: 0;
  }

  .job-subtitle-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .job-subtitle-wrapper .posted {
    margin-left: 0;
    margin-top: 6px;
  }
}

@media only screen and (max-width: 950px) {
  .career-container {
    max-width: 100vw;
    /* padding: 0 20px; */
  }
  .hero_heading {
    font-size: 2.2em;
  }
  .slider-container {
    margin: auto;
  }
  .text {
    padding-top: 20px;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }
  .hero_container {
    padding-top: 80px;
  }
  .company_features {
    grid-template-columns: 1fr 1fr;
  }
  .job_header {
    font-size: 2em;
  }
  .side_nav_list {
    display: none;
  }
  .side_nav_select {
    display: block;
  }
  .job_container {
    flex-direction: column;
  }
  .aside {
    width: 100%;
  }
  .aside .side_nav_select {
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    border-color: grey;
    color: grey;
    margin-bottom: 1rem;
    height: auto;
    border: 1px solid;
  }
  .company_features > li {
    padding: 0.8rem;
  }
  .company_features {
    grid-template-columns: 1fr;
  }
  .block_text {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .job {
    flex-direction: column;
  }
  .left {
    margin-bottom: 1rem;
  }

  .career-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .role-details {
    flex-flow: column;
    align-items: normal;
  }
  .divider {
    display: none;
  }
  .posted {
    text-align: left;
  }
}

.job-overview-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.callout {
  position: relative;
  transition: 0.3s cubic-bezier(0.65, 0.21, 0.6, 0.87);
  padding: 1.5rem;
  margin-top: 5px;
  margin-bottom: 5px;
}
@media print, screen and (min-width: 64em) {
  .callout {
    padding: 70px 100px;
  }
}
.callout.purple {
  color: #fff8e8;
  background: var(--main-bg-color);
}
@media print, screen and (min-width: 64em) {
  .callout.purple {
    background: none;
    color: #fff8e8;
  }
}
.callout h3 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fd2f46;
  transition: 0.3s cubic-bezier(0.65, 0.21, 0.6, 0.87);
}
.callout p {
  margin-bottom: 0;
}
.callout p span {
  margin-left: 20px;
}
.callout .shapes {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  height: 100%;
  overflow: visible;
}
.callout .shapes path {
  transition: 0.3s cubic-bezier(0.65, 0.21, 0.6, 0.87);
}
.callout:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  background: var(--main-bg-color);
  z-index: -1;
  transition: 0.3s cubic-bezier(0.65, 0.21, 0.6, 0.87);
}
@media print, screen and (min-width: 64em) {
  .callout:hover h3 {
    color: #fff8e8;
  }
  .callout:hover:after {
    left: -500px;
    right: -500px;
    background: #fd2f46;
  }
  .callout:hover .shape2 {
    transform: translateX(10px);
  }
  .callout:hover .shape2 {
    transform: translateX(50px);
  }
  .callout:hover .shape3 {
    transform: translateX(100px);
  }
}

@media only screen and (max-width: 950px) {
  .callout h3 {
    font-size: 1.3rem;
  }
}

:root {
  --blue: #3625d3;
  --secondary: #666;
}

.jd_container {
  margin: 100px 60px;
  padding-top: 150px;
  max-width: 1000px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.5;
}

.jd_container section {
  margin-bottom: 3rem;
}

.jd_heading {
  margin-bottom: 3rem;
}
.job-heading-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.job-posted {
  text-align: left;
  font-size: 10px;
  padding: 12px 0px;
  color: gray;
}
.jd_heading > h1 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}
.jd_heading > p {
  color: var(--secondary);
}

.jd_container section h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.list > li {
  margin-bottom: 1rem;
}

.form_container {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
}
.form_container form {
  flex: 1 1;
}
.form_control {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

/* .form_control label{
    margin-bottom: 1rem;
} */
.form_control > input {
  width: 100%;
  padding: 10px 15px;
  /* border: 1px solid rgba(0,0,0,0.2); */
  outline: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: auto !important;
}

.form_control > button {
  padding: 10px 15px;
  border: none;
  background-color: var(--blue);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  height: auto !important;
}
.form_control .required::after {
  content: "*";
  color: red;
  margin-left: 3px;
}
.block_text {
  flex: 1 1;
  margin-left: 5em;
}
.form_container .block_text h1 {
  font-size: 95px;
  font-weight: 500;
}
.career {
  -webkit-text-stroke: 2px var(--blue);
  background-color: transparent;
  transition: 0.3s ease-in;
  color: transparent;
  /* background-color: blue; */
}
.career:hover {
  -webkit-text-fill-color: var(--blue);
}
.job_title {
  font-size: 32px;
  padding: 8px 0px;
  cursor: pointer;
}
.salery {
  padding: 16px 0px;
  font-size: 16px;
}
#buttonid {
  border: 1px solid blue;
  padding: 16px 48px;
  font-size: 16px;
  cursor: pointer;
  margin: 12px 0px;
  color: #000;
}
#buttonid:hover {
  background-color: blue;
  color: #fff;
}
.description_title {
  font-size: 24px;
}
ol {
  margin: 24px 0px;
}
ol li {
  list-style: circle;
  line-height: 1.8;
  font-size: 14px;
}
li {
  font-size: 14px;
}
p > strong {
  font-size: 20px;
  display: inline-block;
  margin: 18px 0px;
}
.apply-job {
  text-align: center;
}
.job-details {
  display: flex;
  align-items: center;
}
.job-designation,
.job-salery {
  font-size: 14px;
}
.awssld__bullets button {
  width: 4px !important;
  height: 4px !important;
  border-radius: 50% !important;
}
.awssld__bullets .awssld__bullets--active {
  transform: scale(1.5) !important;
}
@media only screen and (max-width: 950px) {
  .jd_container {
    max-width: 90vw;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    box-sizing: border-box;
  }
  .jd_container section p {
    text-align: justify;
  }
  .list {
    text-align: justify;
  }
}

@media only screen and (max-width: 600px) {
  .jd_container {
    max-width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }
  .jd_container section h2 {
    font-size: 1.8rem;
  }
  .job-heading-details {
    flex-flow: column;
  }
  .job-details {
    flex-flow: column;
    align-items: normal;
  }
  #buttonid {
    background-color: blue;
    color: #fff;
  }
}

.landing_section {
    width: 100%;
    padding-top: 220px;
    padding-bottom: 0;
}

@media (max-width: 1600px) {
    .landing_section {
        padding-top: 200px;
    }
}

@media (max-width: 1500px) {
    .landing_section {
        padding-top: 180px;
        padding-bottom: 100px;
    }
}

@media (max-width: 750px) {
    .landing_section {
        padding-top: 120px;
        padding-bottom: 75px;
    }
}

.landing_container {
    width: 100%;
    position: relative;
}

.container_content {
    max-width: 1280px;
}

@media (max-width: 750px) {
    .container_content {
        width: 100%;
        padding: 0 32px;
    }
}

@media (max-width: 1050px) {
    .container_content {
        max-width: 650px;
        padding: 0 32px;
    }
}

@media (max-width: 1500px) {
    .container_content {
        max-width: 845px;
        padding: 0 32px;
    }
}

@media (max-width: 1600px) {
    .container_content {
        max-width: 1100px;
    }
}

.landing_main_container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

@media (max-width: 750px) {
    .landing_main_container {
        flex-direction: column;
    }
}

.landing_left {
    width: calc(50% - 12px);
    z-index: 2;
}

@media (max-width: 750px) {
    .landing_left {
        width: 100%;
    }
}

.landing_left p,
.select {
    font-family: Euclid Circular B;
    font-size: 95px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -5.1px;
    color: #000;
    max-width: 520px;
}

@media (max-width: 1500px) {

    .landing_left p,
    .select {
        font-size: 45px;
        letter-spacing: -2.41px;
    }
}

.landing_right {
    width: calc(50% - 12px);
    margin-top: 120px;
    z-index: 2;
}


@media (max-width: 1500px) {
    .landing_right {
        margin-top: 80px;
    }
}

@media (max-width: 1050px) {
    .landing_right {
        width: 55%;
        margin-top: 70px;
    }
}

@media (max-width: 750px) {
    .landing_right {
        width: 100%;
        margin-top: 57px;
    }
}


.landing_right h1 {
    font-family: Euclid Circular B;
    font-size: 30px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color: #7a7a7a;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease, opacity 1.6s ease;
    transition-delay: .6s;
    will-change: transform, opacity;
}

@media (max-width: 1500px) {
    .landing_right h1 {
        font-size: 20px;
        letter-spacing: -.48px;
    }
}

.select {
    /*  background-color: var(--main-bg-color1); */
    position: relative;
    color: var(--main-bg-color1);
    padding: 10px 4px;
}

input.select {
    width: 100%;
}

@media (max-width: 750px) {
    .select {
        height: auto;
        padding: 0px 2px;
    }
}

.landing1_container {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
}

.landing1_container .landing1_left {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    flex-basis: 50%;
}


.landing1_content {
    align-items: flex-end;
    font-family: Euclid Circular B;
    font-size: 28px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color:
        #7a7a7a;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease, opacity 1.6s ease;
    transition-delay: 0s, 0s, 0s;
    transition-delay: .6s;
    will-change: transform, opacity;
    padding-top: 50px;
}

.landing1_title {
    font-size: 70px;
    line-height: 70px;
    font-weight: 500;
    color: #3F0D7A
}

.landing1_right {
    -webkit-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: row;
    flex-basis: 60%;
    padding-left: 60px;
    justify-content: center;
    height: 70vh;
}

.landing1_right_item1 {
    height: 100%;
    width: 100%;
    border-right: 2px solid black;
    overflow: hidden;
    position: relative;
}


.landing1_right_item2 {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}



.landing1_right_item1 img {
    position: absolute;
    border-radius: 50%;
    left: 25%;
    z-index: 1;
    top: 30%;
    height: 320px;
    width: 320px;
    overflow: hidden;
    animation: move_two 15s ease infinite;
    animation-delay: 0s;
    animation-delay: 2s;

}


.landing1_right_item2 img {
    position: absolute;
    border-radius: 50%;
    right: 50%;
    z-index: 1;
    top: 10%;
    height: 320px;
    width: 320px;
    overflow: hidden;
    animation: move_two 15s ease infinite;
    animation-delay: 0s;
    animation-delay: 2s;

}

.landing1_right_letter1 {
    font-size: 210px;
    font-weight: bold;
    position: absolute;
    right: 30%;
    margin: 0;
    color: #3F0D7A;
    overflow: hidden;
}

.landing1_right_letter2 {
    font-size: 210px;
    font-weight: bold;
    position: absolute;
    right: 10%;
    bottom: 0;
    margin: 0;
    color: #3F0D7A;
    overflow: hidden;
}

@media (max-width:900px) {
    .landing1_right {
        display: none;
    }

    .landing1_container .landing1_left {
        flex-basis: 100%;
    }

}


@media (max-width:1500px) {
    .landing1_content h1 {
        font-size: 20px;
        letter-spacing: -.48px
    }
}
.friends_title {
    width: 1340px;
    margin: 0 auto;
    box-sizing: border-box;
    height: auto;
    padding-top : 125px;
    position: relative;
    z-index: 3;
    transition: transform 1s cubic-bezier(.19,1,.22,1)
}

.friends_title_text {
    font-family: Euclid Circular B;
    font-size: 30px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color: #505050;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease,opacity 1.6s ease;
    transition-delay: .6s;
    will-change: transform,opacity;
    
}

@media (max-width: 1740px) {
    .friends_title {
        padding-top:30px;
        padding-right:10px;
    }
    .friends_title_text {
       font-size: 25px;
       padding-left: 30px;
    }
}


@media (max-width: 1223px) {
    .friends_title {
        display: inline;
        padding-top:20px;
        padding-right:10px;
    }
    .friends_title_text {
       font-size: 25px;
       padding-left: 20px;
    }
}

@media (max-width: 750px) {
    .friends_title {
        display: inline;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        height: auto;
        padding-left: 20px;
        padding-top : 10px;
        position: relative;
        z-index: 3;
    }
    .friends_title_text {
       font-size: 25px;
       padding-left: 10px;
    }
}

.section-friends .friends .section-progress {
    top: 240px;

}

@media (max-width: 1740px) {
    .section-friends .friends .section-progress {
        top:140px
    }
}

.section-friends {
    padding-bottom: 0
}

@media (max-width: 767px) {
    .section-friends {
        padding-top:0
    }
}

.section-friends .section-index {
    top: 50px;
    right: calc(50vw - 720px)
}

@media (max-width: 1740px) {
    .section-friends .section-index {
        right:calc(50vw - 512px)
    }
}

.section-friends .wrapper {
    padding-bottom: 200px;
    position: relative
}

@media (max-width: 1740px) {
    .section-friends .wrapper {
        padding-bottom:100px
    }
}

.section-friends .friends-visual-01 {
    position: absolute;
    pointer-events: none;
    top: 100px;
    right: 50%;
    transform: scaleX(-1);
    margin-right: 140px
}

@media (max-width: 1740px) {
    .section-friends .friends-visual-01 {
        width:100px!important;
        top: 82px
    }
}

@media (max-width: 767px) {
    .section-friends .friends-visual-01 {
        left:0
    }
}

.section-friends .friends {
    position: relative;
    padding-bottom: 50px
}

@media (max-width: 1223px) {
    .section-friends .friends {
        padding-bottom:10px
    }
}

@media (max-width: 767px) {
    .section-friends .friends {
        padding-bottom:0
    }
}

.section-friends .friends:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 240px;
    display: block;
    content: "";
}

@media (max-width: 1740px) {
    .section-friends .friends:before {
        height:140px
    }
}

@media (max-width: 767px) {
    .section-friends .friends:before {
        display:none
    }
}

.section-friends .friends__content {
    position: relative;
    z-index: 2;
    width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 240px 40px 240px;
  /*   background: #1e1541 */
}


@media (max-width: 1740px) {
    .section-friends .friends__content {
        width:1024px;
        padding: 40px
    }
}

@media (max-width: 1223px) {
    .section-friends .friends__content {
        width:100%;
        padding: 35px;
    }
}


@media (max-width: 767px) {
    .section-friends .friends__content {
        padding:20px 30px
    }
}

.friends__image {
    width: 100%;
    height: auto;
}

.friends__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.friends__list a {
    width: 25%;
}

.friends__list a.katomaran {
    width: 30%;
}



@media (max-width: 1740px) {
    .friends__list a {
        width: 22%;
    }
    
}

@media (max-width: 1223px) {
    .friends__list a {
        width: 25%;
    }
    
}


@media (max-width: 767px) {
    .friends__list a {
        width: 30%;
    }    
}
.slick-list,.slick-track {
    height: 100%
}

.slick-track {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start
}

.partners_title {
    width: 1340px;
    margin: 0 auto;
    box-sizing: border-box;
    height: auto;
    padding-top : 50px;
    position: relative;
    z-index: 3;
    transition: transform 1s cubic-bezier(.19,1,.22,1)
}

.partners_title_text {
    font-family: Euclid Circular B;
    font-size: 30px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color: #505050;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease,opacity 1.6s ease;
    transition-delay: .6s;
    will-change: transform,opacity;
    
}

@media (max-width: 1740px) {
    .partners_title {
        display: inline;
        padding-top:30px;
        padding-right:10px;
    }
    .partners_title_text {
       font-size: 25px;
       padding-left: 30px;
    }
}


@media (max-width: 1223px) {
    .partners_title {
        display: inline;
        padding-top:20px;
        padding-right:10px;
    }
    .partners_title_text {
       font-size: 25px;
       padding-left: 20px;
    }
}

@media (max-width: 750px) {
    .partners_title {
        display: inline;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        height: auto;
        padding-left: 20px;
        padding-top : 10px;
        position: relative;
        z-index: 3;
    }
    .partners_title_text {
       font-size: 25px;
       padding-left: 10px;
    }
}


/* .section-partners .partners:after {
    position: absolute;
    top: 0;
    right: 140px;
    display: block;
    width: 2px;
    height: 240px;
    content: "";
    z-index: 2
}

@media (max-width: 1740px) {
    .section-partners .partners:after {
        right:100px;
        height: 140px
    }
}

@media (max-width: 1223px) {
    .section-partners .partners:after {
        display:none
    }
} */

.section-partners .partners .section-progress {
    top: 240px;

}

@media (max-width: 1740px) {
    .section-partners .partners .section-progress {
        top:140px
    }
}

.section-partners {
    padding-bottom: 0
}

@media (max-width: 767px) {
    .section-partners {
        padding-top:0
    }
}

.section-partners .section-index {
    top: 50px;
    right: calc(50vw - 720px)
}

@media (max-width: 1740px) {
    .section-partners .section-index {
        right:calc(50vw - 512px)
    }
}

.section-partners .wrapper {
    padding-bottom: 200px;
    position: relative
}

@media (max-width: 1740px) {
    .section-partners .wrapper {
        padding-bottom:100px
    }
}

.section-partners .partners-visual-01 {
    position: absolute;
    pointer-events: none;
    top: 100px;
    right: 50%;
    transform: scaleX(-1);
    margin-right: 140px
}

@media (max-width: 1740px) {
    .section-partners .partners-visual-01 {
        width:100px!important;
        top: 82px
    }
}

@media (max-width: 767px) {
    .section-partners .partners-visual-01 {
        left:0
    }
}

.section-partners .partners {
    position: relative;
    padding-bottom: 100px
}

@media (max-width: 1223px) {
    .section-partners .partners {
        padding-bottom:50px
    }
}

@media (max-width: 767px) {
    .section-partners .partners {
        padding-bottom:0
    }
}

.section-partners .partners:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 240px;
    display: block;
    content: "";
}

@media (max-width: 1740px) {
    .section-partners .partners:before {
        height:140px
    }
}

@media (max-width: 767px) {
    .section-partners .partners:before {
        display:none
    }
}

.section-partners .partners__content {
    position: relative;
    z-index: 2;
    width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 240px ;
  /*   background: #1e1541 */
}


@media (max-width: 1740px) {
    .section-partners .partners__content {
        width:1024px;
        padding: 80px
    }
}

@media (max-width: 1223px) {
    .section-partners .partners__content {
        width:100%;
        padding: 50px calc((100% - 568px) / 2)
    }
}


@media (max-width: 767px) {
    .section-partners .partners__content {
        padding:20px 30px
    }
}

.section-partners .partners__list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    /* border: 1px solid #382879; */
    border: 0px solid var(--client-border-color);
}

.section-partners .partners .slick-dots {
    height: 240px
}

@media (max-width: 1740px) {
    .section-partners .partners .slick-dots {
        height:140px
    }
}

@media (max-width: 767px) {
    .section-partners .partners .slick-dots {
        height:100px
    }
}

.section-partners .partner {
    display: flex;
    width: 25%;
    height: 160px;
    align-items: center;
    justify-content: center;
    position: relative
}

@media (max-width: 1223px) {
    .section-partners .partner {
        height:142px
    }
}

@media (max-width: 767px) {
    .section-partners .partner {
        width:50%;
        height: calc(50vw - 30px)
    }
}

.section-partners .partner img {
    max-width: 60%;
    max-height: 40%
}

@media (max-width: 1223px) {
    .section-partners .partner img {
        max-width:60%
    }
}

.section-partners .partner .left-top {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 1;
    overflow: hidden
}

.section-partners .partner .left-top:after,.section-partners .partner .left-top:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background: var(--client-border-color);
    transition: transform 1s cubic-bezier(.19,1,.22,1)
}

.section-partners .partner .left-top:before {
    width: 100%;
    height: 2px;
    transform: translateX(-110%)
}

.section-partners .partner .left-top:after {
    width: 2px;
    height: 100%;
    transform: translateY(-110%)
}

.section-partners .partner .right-bottom {
    pointer-events: none;
    bottom: -2px;
    right: -2px;
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 1;
    overflow: hidden
}

.section-partners .partner .right-bottom:after,.section-partners .partner .right-bottom:before {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    content: "";
    background: var(--client-border-color);
    transition: transform 1s cubic-bezier(.19,1,.22,1)
}

.section-partners .partner .right-bottom:before {
    width: 100%;
    height: 2px;
    transform: translateX(110%)
}

.section-partners .partner .right-bottom:after {
    width: 2px;
    height: 100%;
    transform: translateY(110%)
}

.section-partners .partner:hover .left-top:after,.section-partners .partner:hover .left-top:before,.section-partners .partner:hover .right-bottom:after,.section-partners .partner:hover .right-bottom:before {
    transform: none
}

.section-partners .partner:after {
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* border: 1px solid #382879 */
    border: 0px solid var(--client-border-color)
}

.partners .partner__image { /* Safari 6.0 - 9.0 */
    filter: saturate(0);
}



.partner:hover img { /* Safari 6.0 - 9.0 */
    filter: saturate(1);
}
.partners__list {
    width: 960px;
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 999;
    opacity: 1;
}
.bgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: opacity 1s ease;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}

.bgContainerIcon {
    width: 550px;
}

.main_content {
    position: relative;
    z-index: 2;
}
.lets-talk {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    text-align: center;
    color: #000;
}

.lets-talk  .container {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: 2;
}

@media (max-width: 576px) {
    .lets-talk {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .lets-talk  .container {
        padding-left:30px;
        padding-right: 30px
    }
}

.lets-talk .align-center {
    align-items: center!important;
}

.lets-talk .justify-center {
    justify-content: center!important;
}

.lets-talk {
    -webkit-font-smoothing: antialiased!important;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.lets-talk .sub {
    margin-bottom: 15px;
    font-size: 2em;
    color: var(--sub-title);
}

.lets-talk a {
    display: inline-block;
    text-align: center;
    color: #000;
    position: relative;
    padding-bottom: .3em;
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    font-size: 8em;
    font-family: "Euclid Circular B";
    line-height: .92857143em;
    letter-spacing: -.02328571em;
}

@media (max-width: 576px) {
    .lets-talk a {
        font-size: 6em;
    }
}

.lets-talk a svg {
    position: absolute;
    bottom: -62px;
    width: 62px;
    left: 50%;
    margin-left: -31px;
    transform-origin: center;
    transform: translateY(50px) rotate(-45deg);
    opacity: 0;
    transition: transform .4s cubic-bezier(.18,0,.16,1),opacity .4s cubic-bezier(.18,0,.16,1)
}

@media (max-width: 1440.98px) {
    .lets-talk a svg {
        width:44px;
        bottom: -44px
    }
}

@media (max-width: 1024.98px) {
    .lets-talk a svg {
        width:32px;
        bottom: -32px
    }
}

@media (max-width: 768.98px) {
    .lets-talk a svg {
        width:40px;
        position: static;
        opacity: 1;
        display: block;
        margin: 25px auto 0;
        transform: translateY(0) rotate(0deg);
        width: 25px
    }
}

.lets-talk a:hover svg {
    opacity: 1;
    transform: translateY(0) rotate(0deg)
}
 .video_frame {
    width: 800px;
    height: 500px;
}



@media (max-width: 1220px) {
    .video_frame {
        width: 600px;
        height: 400px;
    }
    
}

@media (max-width: 900px) {
    .video_frame {
        width: 400px;
        height: 300px;
    }
}

@media (max-width: 768px) {
    .video_frame {
        width: auto;
        height: auto;
    }
}


/*
@media (max-width: 1220px) {
    .video_frame {
        width: 600px;
        height: 400px;
    }
    
}

 */
.from_section{ 
    width: 100%;
    padding-top: 180px;
    padding-bottom: 0;
}

@media (max-width: 1600px) {
    .from_section {
        padding-top: 150px;
    }    
}

@media (max-width: 1500px) {
    .from_section {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media (max-width: 750px) {
    .from_section {
        padding-top: 50px;
        padding-bottom: 75px;
    }  
}

.form_content {
    max-width: 1500px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.form_container {
    width:100%;
    position: relative;
    z-index: 10;
}

.from_section .container_content {
    font-size: 30px;
}

.form_title {
    font-size: 60px;
    padding-bottom: 80px;
}

@media (max-width: 1600px) {
    .form_title {
        font-size: 50px;
        padding-left: 10px;
        padding-bottom: 70px;
    }  
}

@media (max-width: 1500px) {
    .form_title {
        font-size: 40px;
        padding-bottom: 60px;
    }
}

@media (max-width: 750px) {
    .form_title {
        font-size: 35px;
      
        padding-bottom: 40px;
    }
}

.contact_form {
    font-size: 30px;
}

.input-field {
    display: block;
    padding:20px 0px; 
    width:50%;
    height: 20px;
    background-color: none;
    letter-spacing: .01875em;
    font-weight: 300;
    border-bottom: 2px solid var(--main-bg-color);
    transition:         0.08s ease-in;
    -o-transition:      0.08s ease-in;
    -ms-transition:     0.08s ease-in;
    -moz-transition:    0.08s ease-in;
    -webkit-transition: 0.08s ease-in;
}

@media (max-width: 1600px) {
    .input-field {
        width:50%;
    }  
}

@media (max-width: 1500px) {
    .input-field {
        width:60%;
    }
}

@media (max-width: 750px) {
    .input-field {
        width:80%;
    }
}

.input-field:after {
    padding-bottom: 130px 0px;
}

.input-container {
    padding-bottom: 20px;
    height: auto;
    line-height: 2em;;
}

@media (max-width: 1600px) {
    .input-container {
        padding-bottom: 20px;
        padding-left: 15px;
    } 
}



.input-field.textarea {
    resize: none;
    padding: 0;
    height: 100px !important;
    transition: all .3s;
}

.input-field.textarea:hover {
    height: 200px !important;
}

.error {
    color:red;
    font-size: 20px;
    height: 30px;
}

.btn {
    position: relative;
    line-height: 0;
    border:2px solid var(--main-bg-color);
    padding: 32px;
    color: var(--main-bg-color);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    height: auto;
    transition:         0.08s ease-in;
    -o-transition:      0.08s ease-in;
    -ms-transition:     0.08s ease-in;
    -moz-transition:    0.08s ease-in;
    -webkit-transition: 0.08s ease-in;
    overflow:hidden;
    z-index: 1;
}

@media (max-width: 1600px) {
    .btn {
        margin-left: 15px;
    }  
}

@media (max-width: 1500px) {
    .btn {
        margin-left: 15px;
    } 
}

@media (max-width: 750px) {
    .btn {
        margin-left: 15px;
    } 
}

  
.fill:hover {
    color: whitesmoke;
}

.fill:before {
    content: "";
    position: absolute;
    background: var(--main-bg-color);
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.09s ease-in;
}

.fill:hover:before {
    top: 0;
}

.message_response {
    padding-left: 30px;
}
.enter-y {
    opacity: 1;
    transform: translate(0) scale(1) rotate(0);
}

.delay {
    transition-delay: .75s!important;
}

.bg-text {
    font-size: 40vw;
    font-family: 'Euclid Circular B';
    color: #f5f5f5;
    z-index: 4;
    position: relative;
    transition: color .5s;
    letter-spacing: -10px;
    pointer-events: none
}
.shadow__text {
    position: absolute;
    margin-left: 5px;
}

.bg-text span {
    display: block;
    position: relative;
    transition: color .5s;
    font-weight: 100;
}
.contact_section {
    width: 100%;
    padding-top: 240px;
    padding-bottom: 0;
}

@media (max-width: 1600px) {
    .contact_section {
        padding-top: 180px;
    }    
}

@media (max-width: 1500px) {
    .contact_section {
        padding-top: 150px;
        padding-bottom: 100px;
    }
}

@media (max-width: 750px) {
    .contact_section {
        padding-top: 120px;
        padding-bottom: 75px;
    }  
}

.contact_container {
    width:100%;
    position: relative;
    z-index: 3;
}

.contact_main_container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

@media (max-width: 750px) {
    .contact_main_container {
        flex-direction: column;
    }
}


.contact_left {
    width: calc(50% - 12px);
    z-index: 2;
}

@media (max-width: 750px) {
    .contact_left {
        width: 100%;
    }
}

.contact_left p {
    font-family: Euclid Circular B;
    font-size: 100px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -5.1px;
    color: #000;
    max-width: 520px;
}

@media (max-width: 1500px) {
    .contact_left p {
        font-size: 45px;
        letter-spacing: -2.41px;
    }
}

.contact_right {
    width: calc(50% - 12px);
    margin-top: 150px;
    z-index: 2;
}

.contact_right p {
    font-family: Euclid Circular B;
    font-size: 40px;
    line-height: 1.67;
    letter-spacing: -.7px;
    color: #7a7a7a;
    transform: translateY(0);
    opacity: 1;
    transition: transform 1.2s ease,opacity 1.6s ease;
    transition-delay: .6s;
    will-change: transform,opacity;
}

@media (max-width: 1500px) {
    .contact_right p {
        font-size: 30px;
        letter-spacing: -.48px;  
    }
}

@media (max-width: 1050px) {
    .contact_right {
        margin-top: 80px;
    }
}

@media (max-width: 1050px) {
    .contact_right {
        width: 55%;
        margin-top: 70px;
    }
}

@media (max-width: 750px) {
    .contact_right {
        width: 100%;
        margin-top: 57px;
    }
}

.line-1{
    position: relative;
    top: 50%;  
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
  animation: typewriter 4s steps(44) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
  from{width: 0;}
  to{width: 24em;}
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(255,255,255,.75);}
  to{border-right-color: transparent;}
}

.map_section {
    border:0;
    position: relative;
    z-index: 3;
    padding-top: 60px;
    padding-bottom: 0;
    width: 100%;
    height: 500px;
}

body.-contact .footer {
    margin-top: 0 !important;
}

.contact_left .select {
    background-color: var(--main-bg-color1);
    color: white;
    padding: 10px 4px;
}

@media (max-width: 750px) {
    .contact_left .select {
        padding: 2px 2px;
    }
}

.line {
    position: relative;
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.line:after {
    position: absolute;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 0;
    background: #000;
    transition: all 0.3s ease;
}

.line:hover:after {
    width: 100%
}
/* Color schemes */
.content {
	--color-text: #efedeb;
	--color-bg: #0e0e0e;
	--color-link: #1920f3;
	--color-link-hover: #555;
	--color-info: #fff;
	--color-main-text: #1d1c1c;
	--color-scroll-text: #3625d3;
	--font-scroll-text: 'Roboto Mono', monospace;
	--fontsize-scroll-text: 8vw;
}

.content {
	padding-top:90px;
}

.content__main {
	padding-top:100px;
	color: var(--color-main-text);
}

.content__text::before {
	content: '';
	position: absolute;
	left: 0;
	height: 69%;
	bottom: 0;
	width: calc(6vmax - 1.5rem);
	background: var(--color-link);
}

.content {
	position: relative;
	display: block;
}

.content__section {
	position: relative;
	text-align: right;
	padding: 0 6vmax;
}

.content__section:first-child {
	margin: 0 0 30vh;
	margin-top: 15vh;
	height: calc(100vh - 9.875em);
	text-align: left;
}

/* .content__section:last-child {
	margin: 30vh 0 0;
}

.content__section:first-child,
.content__section:last-child {
	height: calc(100vh - 9.875em);
	text-align: left;
} */

.content__link {
	display: block;
	position: relative;
	max-width: 100%;
	margin: 10vh 0 30vh auto;
}

.content__link--nobottom {
	margin-bottom: 10vh;
}

.content__img {
	position: relative;
	display: block;
	max-width: 90%;
	margin: 0 0 0 auto;
}

.content__3d {
	width: 700px;
	height: 500px;
	position: relative;
	margin: 0 0 0 auto;
}

.content__main,
.content__text {
	color: var(--color-scroll-text);
	font-size: var(--fontsize-scroll-text);
	font-family: var(--font-scroll-text);
	margin: 0;
	line-height: 1;
	text-transform: lowercase;
	font-weight: normal;
}

.content__main,
.content__contact {
	position: absolute;
	bottom: calc(var(--fontsize-scroll-text) * 1.5 + 6vmax);
}

.content__main span {
	display: block;
}

.content__text {
	position: absolute;
	bottom: 6vmax;
	left: 0;
	padding-left: 6vmax;
	z-index: 1000;
	line-height: 0.8;
}

.js .ioapi .content__text {
	position: fixed;
	opacity: 0;
	white-space: pre;
}

.content__text span {
	display: inline-block;
}

.content__contact {
	font-size: calc(var(--fontsize-scroll-text) / 2);
	font-family: var(--font-scroll-text);
}

.content__contact a:last-child {
	display: block;
}

.content__text span[data-initial=" "] {
	width: 40px;
}


@media screen and (max-width: 50em) {

	.content__main {
		padding-top: 240px;
	}

	
	.codrops-header {
		display: block;
		text-align: center;
		padding: 2.5rem 1rem 0;
	}

	.codrops-header__title {
		margin: 1rem 0;
	}

	.demos {
		margin: 0;
	}

	.info {
		padding: 0;
		position: relative;
		writing-mode: initial;
		text-align: center;
		bottom: auto;
		right: auto;
		margin: 0;
	}

	.github {
		display: none;
	}

	.content__text,
	.js.ioapi .content__text {
		bottom: 7rem;
	}

	.content__text span[data-initial=" "] {
		width: 28px;
	}


	.content__section:first-child
	/* .content__section:last-child */ {
		height: calc(100vh - 13.875em);
	}

	.content__main,
	.content__contact {
		bottom: calc(var(--fontsize-scroll-text) * 1.5 + 15vh);
	}
}



.section.section__products {
    width: 100%;
    padding-top: 240px;
    padding-bottom: 0;
    z-index: 5;
    position: relative;
}

.section__products {
    width: 100%;
    padding-top: 220px;
    padding-bottom: 0;
}

.wrapper {
    position: relative;
    display: block;
    margin: 0 auto;
    right: 0;
    max-width: 1300px;
    width: 90%;
    z-index: 1;
}

.wrap-padding {
    padding: 0 100px;
}

@media screen and (max-width: 1200px) {
    .wrap-padding {
        padding: 0 50px;
    }
}

@media screen and (max-width: 1100px) {
    .wrap-padding {
        padding: 0;
    }
}

.product {
    width: calc(50% - 44px);
    display: inline-block;
    margin: 0;
    margin-right: 80px;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
    .product {
        width: 100%;
        margin: 0;
    }
}

.products_title {
    font-size: 40px;
    padding-bottom: 40px;
}

@media screen and (max-width: 600px) {
    .products_title {
        font-size: 35px;
        margin-left: 0;
        padding-bottom: 30px;
    }
}



.product.img:hover {
    width: 105%;
}

.product:nth-child(2n + 2) {
    margin-right: 0;
    padding-top: 80px;
}

@media screen and (max-width: 600px) {
    .product:not(:first-of-type) {
        margin-top: 30px;
        padding-top: 0;
        width: 100%;
    }
}

.product .img {
    position: relative;
    width: 100%;
    padding-bottom: 58%;
    overflow: hidden;
}

.product .img:hover {
    width: 105% !important;
}

.product .text {
    text-align: left;
    position: relative;
    padding: 10%;
    background: transparent;
    transition: all .5s;
}

@media screen and (max-width: 800px) {
    .product .text {
        padding-left: 0 !important;
    }
}

@media screen and (max-width: 600px) {
    .product .text {
        padding: 5% !important;
    }
}

.product .text h5 {
    margin: 0;
    padding-bottom: 5px;
    font-size: 30px;
    letter-spacing: -1px;
    font-weight: 400;
}

.product .text p {
    font-weight: 400;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: -.015em;
    margin: 0;
}

.product .img .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: all .7s;
}

.c-grey {
    color: #343434;
    transition: all .3s
}

.c-lightgrey {
    color: #9e9e9e;
    transition: all .3s
}

.enter-y {
    will-change: transform, opacity;
    transform: translateY(50px) rotate(2deg);
    opacity: 0;
    transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -webkit-transform: translateY(50px) rotate(2deg);
    -moz-transform: translateY(50px) rotate(2deg);
    -ms-transform: translateY(50px) rotate(2deg);
    -o-transform: translateY(50px);
    -webkit-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -moz-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -ms-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -o-transition: all 1.2s cubic-bezier(.37, .01, 0, .98)
}

.enter-y-r {
    will-change: transform, opacity;
    transform: translateY(-50px) rotate(2deg);
    opacity: 0;
    transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -webkit-transform: translateY(-50px) rotate(2deg);
    -moz-transform: translateY(-50px) rotate(2deg);
    -ms-transform: translateY(-50px) rotate(2deg);
    -o-transform: translateY(-50px);
    -webkit-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -moz-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -ms-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -o-transition: all 1.2s cubic-bezier(.37, .01, 0, .98)
}

.enter-x {
    will-change: transform, opacity;
    transform: translateX(50px) rotate(2deg);
    opacity: 0;
    transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -webkit-transform: translateX(50px) rotate(2deg);
    -moz-transform: translateX(50px) rotate(2deg);
    -ms-transform: translateX(50px) rotate(2deg);
    -o-transform: translateX(50px);
    -webkit-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -moz-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -ms-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -o-transition: all 1.2s cubic-bezier(.37, .01, 0, .98)
}

.enter-x-r {
    will-change: transform, opacity;
    transform: translateX(-50px) rotate(2deg);
    opacity: 0;
    transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -webkit-transform: translateX(-50px) rotate(2deg);
    -moz-transform: translateX(-50px) rotate(2deg);
    -ms-transform: translateX(-50px) rotate(2deg);
    -o-transform: translateX(-50px) rotate(2deg);
    -webkit-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -moz-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -ms-transition: all 1.2s cubic-bezier(.37, .01, 0, .98);
    -o-transition: all 1.2s cubic-bezier(.37, .01, 0, .98)
}

.show .delay-1 {
    transition-delay: .05s !important
}

.show .delay-2 {
    transition-delay: .1s !important
}

.show .delay-3 {
    transition-delay: .15s !important
}

.show .delay-4 {
    transition-delay: .2s !important
}

.show .delay-5 {
    transition-delay: .25s !important
}

.show .delay-6 {
    transition-delay: .3s !important
}

.show .delay-7 {
    transition-delay: .35s !important
}

.show .delay-8 {
    transition-delay: .4s !important
}

.show .delay-9 {
    transition-delay: .45s !important
}

.show .delay-10 {
    transition-delay: .5s !important
}

.show .enter-y,
.show .enter-x,
.show .enter-y-r,
.show .enter-x-r {
    opacity: 1;
    transform: translate(0) scale(1) rotate(0)
}



.dw-container {
    max-width: calc(100% - 80px);
    margin: 0 auto;
    position: relative;
}

.dw-wrapper {
    width: 1180px;
}

.dw-part {
    text-align: center;
}

.dw-product {
    text-align: left;
    font-family: inherit;
    font-weight: inherit;
    font-variation-settings: inherit;
    font-style: inherit;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit;
    color: inherit;
}


@font-face {
    src: url(/static/media/Bahnschrift.133bf7a8.woff2) format('woff2-variations');
    font-family: 'Bahnschrift';
    font-style: normal;
}

.dw-name {
    padding-top: 130px;
}

.dw-variable-font {
    font-variation-settings: 'wght'800, 'opsz'20;
    font-size: 5em;
    margin-top: 0;
    color: var(--pink);
    font-family: Bahnschrift;
}

@media (max-width: 575.98px) {
    .dw-name {
        /*  font-size: 50px;
        font-weight: 400; */
    }

    .dw-variable-font {
        font-size: 3em;
    }
}

.dw-f-one {
    width: 100%;
    -webkit-user-select: none;
            user-select: none;
}

.dw-f-one-part {
    margin: 20px 0;
}

.dw-about {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 50px 0px;
}

.dw-about .dw-para,
.dw-about .dw-desc {
    flex-basis: 50%;
}

@media (max-width: 768px) {
    .dw-about {
        padding: 0;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 72px;
    }

    .dw-desc {
        margin-left: 0;
        margin-bottom: 30px;
        order: -1;
    }
}



@media (max-width: 1110px) {
    .dw-about {
        padding: 0;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 72px;
    }

    .dw-about .dw-desc {
        margin-bottom: 25px;
        order: -1;
        width: 100%;
        justify-content: center;
    }

    .dw-about .dw-para {
        max-width: 100%;
    }

    .dw-para .dw-content-text {
        text-align: center;
    }

    .dw-content-text {
        font-size: 1.05rem;
    }
}

.dw-product-share-btn {
    margin:  0 48px;
}

.dw-para {
    max-width: 580px;
}

.dw-content-text {
    line-height: 1.9;
    -webkit-user-select: text;
            user-select: text;
    font-size: 1.25rem;
    color: #595959;
}


.dw-desc {
    display: flex;
    flex: none;
    height: 100%;
    justify-content: flex-end;
}

.dw-cap {
    font-size: 23px;
    font-weight: 500;
}

.dw-list {
    list-style-type: none;
    font-size: 20px;
    font-weight: 400;
    margin-left: 20px;
    border-left: 4px solid #3625D3;
}

.dw-list li {
    margin: 2px 0 10px 10px;
}


.dw-video {
    padding-top: 60px;
    padding-bottom: 60px;
}

.dw-video.dw-youtube {}

.dw-video video {
    width: 100%;
}

.dw-content {
    padding-top: 50px;
}

.dw-content-part {
    padding: 20px 0;
}

.dw-para-main {
    max-width: 400px;
}

.dw-flex-para {
    display: flex;
    padding: 0 100px;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
}

.dw-para-container {
    padding: 0 50px;
}

.dw-car {
    max-width: 400px;
    display: flex;
    align-items: center;
}



/* Extra small devices (portrait phones, less than 576px)*/

@media (max-width: 575.98px) {
    .dw-text .dw-para-container {
        padding: 0
    }

    .dw-text .dw-flex-para {
        padding: 0;
    }


}

.dw-para-list {
    display: flex;
    justify-content: center;
}

.dw-para-list ul {
    padding: 10px 0;
}

.dw-para-list ul li {
    padding: 6px 0;
    font-size: 14px;
    list-style: square;
    line-height: 2;
    font-weight: lighter;
    color: black;
}

@media (max-width: 575.98px) {
    .dw-para-list ul {
        padding-left: 20px;
    }
}


/*buttons*/

.dw-other-product {
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
}

.dw-others {
    position: relative;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.dw-others.dw-previous span {
    left: 150%;
}

.dw-others.dw-previous .dw-arrow {
    transform: translate(20px, 0);
    transform-origin: 0% 50%;
}

.dw-others.dw-previous .dw-arrow .dw-arrow-line {
    transform: scaleX(0.4);
    transform-origin: 0% 50%;
}

.dw-others.dw-next {
    right: 10px;
}

.dw-others.dw-next .dw-next-previous-name {
    right: 150%;
}

.dw-others.dw-next .dw-arrow {
    transform: scaleX(-1) translate(60px, 0);
    transform-origin: 100% 50%;
}

.dw-others.dw-next .dw-arrow .dw-arrow-line {
    transform: scaleX(0.4);
    transform-origin: 0% 50%;
}

.dw-others:hover.dw-previous .dw-arrow .dw-arrow-line {
    transform: scaleX(1);
}

.dw-others:hover.dw-previous .dw-circle-blue circle {
    stroke-dashoffset: 0;
}

.dw-others:hover.dw-next .dw-arrow .dw-arrow-line {
    transform: scaleX(1);
}

.dw-others:hover.dw-next .dw-circle-blue circle {
    stroke-dashoffset: 0;
}

.dw-others:hover .dw-next-previous-name {
    opacity: 1;
}

.dw-others:focus {
    outline: none;
}

.dw-others .dw-next-previous-name {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 0 10px;
    opacity: 0;
    transition: all 0.3s ease-out;
    font-weight: 600;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: #3625D3;
    font-size: 0.76471rem;
}

.dw-others .dw-arrow {
    width: 50px;
    transition: all 0.3s ease-out;
}

.dw-others .dw-arrow * {
    transition: all 0.3s ease-out;
}

.dw-others .dw-circle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    z-index: -2;
    transition: all 0.5s ease-out;
}

.dw-others .dw-circle circle {
    stroke-dasharray: 46.25px;
    stroke-dashoffset: 0px;
}

.dw-others .dw-circle-blue {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    z-index: -1;
    transition: all 0.5s ease-out;
}

.dw-others .dw-circle-blue circle {
    transition: all 0.4s ease-out;
    stroke-dasharray: 92.5px;
    stroke-dashoffset: 92.5px;
}

.dw-svg {
    padding: 30px;
}

@media (max-width: 575.98px) {
    .dw-svg .dw-others .dw-circle-blue {
        width: 50px;
        height: 50px;
    }

    .dw-svg .dw-others .dw-circle {
        width: 50px;
        height: 50px;
    }

    .dw-svg .dw-others.dw-next .dw-arrow .dw-arrow-line {
        transform: scaleX(0.3);
    }

    .dw-svg .dw-others.dw-previous .dw-arrow .dw-arrow-line {
        transform: scaleX(0.3);
    }

    .dw-svg .dw-others.dw-next {
        right: 0;
    }

    .dw-svg .dw-others.dw-next .dw-arrow {
        transform: scaleX(-1) translate(70px, 0);
    }

    .dw-svg .dw-others:hover.dw-next .dw-arrow .dw-arrow-line {
        transform: scaleX(0.8);
    }

    .dw-svg .dw-others:hover.dw-previous .dw-arrow .dw-arrow-line {
        transform: scaleX(0.8);
    }

    .dw-others.dw-previous span {
        left: 120%;
    }

    .dw-others.dw-next .dw-next-previous-name {
        right: 120%;
    }

    .dw-others .dw-next-previous-name {
        letter-spacing: 0.9px;
        font-size: 0.7rem;
    }

    .dw-svg {
        padding: 10px;
    }
}





/* Image Grid */

.dw-container-yola {
    padding: 130px 50px;
    background-color: #F6F7FF;
}

.dw-grid-wrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    color: #444;
}

@media (max-width: 575.98px) {
    .dw-main .dw-container-yola .dw-grid-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
    }

    .dw-main .dw-container-yola {
        padding: 100px 0;
    }
}

@media (max-width: 991.98px) {
    .dw-desc {
        margin-left: 0;
        margin-bottom: 30px;
        order: -1;
    }
}

.dw-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-select: none;
            user-select: none;
}

.ytp-pause-overlay,
.ytp-title,
.ytp-show-cards-title,
.ytp-show-tiles {
    display: none !important;
}
.cb-loader_overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 99;
    max-width: 0%;
    background: #fff;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s .3s, max-width 0s 1.3s;
    transform-origin: center bottom
}

.cb-loader_overlay-img {
    position: fixed;
    top: 40%;
    left: 0;
    right: 0;
    margin: -40px 0 0 0;
    text-align: center;
    opacity: 0;
    transition: transform .5s ease-in-out, opacity .5s
}

.cb-loader_overlay-img svg {
    width: 200px
}

.cb-loader_overlay.-front {
    z-index: 1010
}

.cb-loader_overlay.-visible {
    max-width: 100%;
    pointer-events: auto;
    opacity: 1;
    transition-delay: .3s, 0s;
    transition-duration: .3s, 0s
}

.cb-loader_overlay.-visible .cb-loader_overlay-img {
    opacity: 1;
    transition-delay: .3s
}

.path {
    stroke-dasharray: 271.9;
    stroke-dashoffset: 271.9;
    animation: dash 2s linear forwards;
}


.path1 {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: dash1 2s linear forwards;
}

@keyframes dash1 {
    from {
        stroke-dashoffset: 400;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 271.9;
    }

    to {
        stroke-dashoffset: 0;
    }
}

.st0 {
    fill: none;
    stroke: #00AEEF;
    stroke-width: 0.3;
    stroke-miterlimit: 10;
}

.st1 {
    fill: none;
    stroke: #00AEEF;
    stroke-width: 0.25;
    stroke-miterlimit: 10;
}

.st2 {
    fill: none;
    stroke: #00AEEF;
    stroke-width: 0.8;
    stroke-miterlimit: 10;
}

.cls-1{
    font-size:25px;
    font-family:EuclidCircularB-Regular, Euclid Circular B;
    opacity:0.99;
}
/* :root {
  --main-bg-color: #0700fffc;  
  --main-bg-color1: #0a03ff ;
  --main-selection: rgba(7,0,255,0.99);
  --main-selection-text : #fff;
  --logo-fill : #27aae1;
  --anchor-bg-color: #f5f5f5;
  --client-border-color : #0a03ff;
} */

:root {
  --main-bg-color: #3625d3;
  --main-bg-color1: #3625d3;
  --main-selection: #3625d3;
  --main-selection-text: #fff;
  --logo-fill: #3625d3;
  --anchor-bg-color: #f5f5f5;
  --sub-title: #7a7a7a;
  --client-border-color: #0a03ff;
  --button-color: #3625d3
}


body {
  font-family: Helvetica, Arial;
}


@font-face {
  font-family: Euclid Circular B;
  font-display: swap;
  src: url(/static/media/EuclidCircularB-Bold.90bcd347.woff2) format("woff2"), url(/static/media/EuclidCircularB-Bold.c170b1ea.woff) format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: Euclid Circular B;
  font-display: swap;
  src: url(/static/media/EuclidCircularB-Semibold.91208401.woff2) format("woff2"), url(/static/media/EuclidCircularB-Semibold.2b035b72.woff) format("woff");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: Euclid Circular B;
  font-display: swap;
  src: url(/static/media/EuclidCircularB-Light.691992a6.woff2) format("woff2"), url(/static/media/EuclidCircularB-Light.7566461d.woff) format("woff");
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: Euclid Circular B;
  font-display: swap;
  src: url(/static/media/EuclidCircularB-Medium.e2292c94.woff2) format("woff2"), url(/static/media/EuclidCircularB-Medium.a6bf2cdc.woff) format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: Euclid Circular B;
  font-display: auto;
  src: url(/static/media/EuclidCircularB-Regular.b004e2c2.woff2) format("woff2"), url(/static/media/EuclidCircularB-Regular.573f4b83.woff) format("woff");
  font-weight: 400;
  font-style: normal
}


a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block
}

body {
  line-height: 1
}

ol,
ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

*,
:after,
:before {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

button,
input,
select,
textarea {
  color: inherit;
  font-size: inherit;
  font-style: inherit;
  font-family: inherit;
  border-radius: 0;
  -webkit-padding-start: 0;
  align-items: flex-start;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  line-height: 1em
}

button,
input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=file],
input[type=month],
input[type=number],
input[type=password],
input[type=reset],
input[type=search],
input[type=submit],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select,
textarea {
  height: 1em;
  -webkit-appearance: none;
  appearance: none
}

input[type=color] {
  width: 1em
}

input::-ms-clear {
  display: none
}

details,
summary {
  -webkit-appearance: none;
  appearance: none
}

body {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
       text-size-adjust: 100%
}

mark {
  background: none
}

hr {
  height: 1px;
  margin: 0;
  padding: 0
}

u {
  text-decoration: none
}

table th {
  text-align: left
}

a {
  color: inherit;
  outline: none;
  text-decoration: none
}

* {
  box-sizing: border-box
}

body {
  font-family: Euclid Circular B;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

body {
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Euclid Circular B;
  background-color: #f5f5f5
}


.container {
  width: 100%;
}

.dw-loader_fill {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  pointer-events: none;
  transform: scaleY(0);
  transform-origin: top center;
  transition: transform .5s ease, height 0s .5s
}

.dw-loader_fill.-visible {
  pointer-events: auto;
  height: 100%;
  transform: scaleY(1);
  transition: transform 0s, height 0s;
  animation: cb-loader_fill-show .5s ease
}

@keyframes cb-loader_fill-show {
  0% {
    transform: scaleY(0);
    transform-origin: bottom center
  }

  100% {
    transform: scaleY(1);
    transform-origin: bottom center
  }
}

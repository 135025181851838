.link_menu a.active{
    background-color: var(--anchor-bg-color);
    padding: 5px;
    color: var(--main-bg-color) !important;
}

.link_menu a {
    transition: .5s;
}

.link_menu a:hover{
    background-color: var(--anchor-bg-color);
    color:#000
}


.dw-cursor {
    display: none;
    position: fixed;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    z-index: 99999;
    color: inherit;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 0 solid;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: saturate(2);
    filter: saturate(2);
    opacity: .8;
    pointer-events: none;
    -webkit-transition: color .5s,width .3s,height .3s,margin .3s,border-width .5s;
    -o-transition: color .5s,width .3s,height .3s,margin .3s,border-width .5s;
    -moz-transition: color .5s,width .3s,height .3s,margin .3s,border-width .5s;
    transition: color .5s,width .3s,height .3s,margin .3s,border-width .5s;
    will-change: color,width,height,margin,border-with,transform
}

@media (min-width: 1280px) {
    .dw-cursor {
        display:block
    }
}

.dw-cursor.-visible {
    margin: -23px 0 0 -23px;
    width: 46px;
    height: 46px;
    border-width: 2px
}

.dw-cursor.-mousedown {
    margin: -18px 0 0 -18px;
    width: 36px;
    height: 36px
}

.dw-cursor.-move {
    width: 34px;
    height: 34px
}

.dw-cursor.-disabled,.dw-cursor.-hidden {
    margin: 0;
    width: 0;
    height: 0;
    border-width: 0
}

.lets-talk {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    text-align: center;
    color: #000;
}

.lets-talk  .container {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: 2;
}

@media (max-width: 576px) {
    .lets-talk {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .lets-talk  .container {
        padding-left:30px;
        padding-right: 30px
    }
}

.lets-talk .align-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}

.lets-talk .justify-center {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}

.lets-talk {
    -webkit-font-smoothing: antialiased!important;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.lets-talk .sub {
    margin-bottom: 15px;
    font-size: 2em;
    color: var(--sub-title);
}

.lets-talk a {
    display: inline-block;
    text-align: center;
    color: #000;
    position: relative;
    padding-bottom: .3em;
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    font-size: 8em;
    font-family: "Euclid Circular B";
    line-height: .92857143em;
    letter-spacing: -.02328571em;
}

@media (max-width: 576px) {
    .lets-talk a {
        font-size: 6em;
    }
}

.lets-talk a svg {
    position: absolute;
    bottom: -62px;
    width: 62px;
    left: 50%;
    margin-left: -31px;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translateY(50px) rotate(-45deg);
    transform: translateY(50px) rotate(-45deg);
    opacity: 0;
    -webkit-transition: transform .4s cubic-bezier(.18,0,.16,1),opacity .4s cubic-bezier(.18,0,.16,1);
    -webkit-transition: opacity .4s cubic-bezier(.18,0,.16,1),-webkit-transform .4s cubic-bezier(.18,0,.16,1);
    transition: opacity .4s cubic-bezier(.18,0,.16,1),-webkit-transform .4s cubic-bezier(.18,0,.16,1);
    transition: transform .4s cubic-bezier(.18,0,.16,1),opacity .4s cubic-bezier(.18,0,.16,1);
    transition: transform .4s cubic-bezier(.18,0,.16,1),opacity .4s cubic-bezier(.18,0,.16,1),-webkit-transform .4s cubic-bezier(.18,0,.16,1)
}

@media (max-width: 1440.98px) {
    .lets-talk a svg {
        width:44px;
        bottom: -44px
    }
}

@media (max-width: 1024.98px) {
    .lets-talk a svg {
        width:32px;
        bottom: -32px
    }
}

@media (max-width: 768.98px) {
    .lets-talk a svg {
        width:40px;
        position: static;
        opacity: 1;
        display: block;
        margin: 25px auto 0;
        -webkit-transform: translateY(0) rotate(0deg);
        transform: translateY(0) rotate(0deg);
        width: 25px
    }
}

.lets-talk a:hover svg {
    opacity: 1;
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg)
}